import React, { Component, ReactElement } from 'react';
import './App.css';
import MainFrame from './page/MainFrame'
import { configEnum, campName, campSkin, isDebug, theQuestions } from './config/configEnums'
//import { titleString, logEvent } from './util/utilFunctions'
import { logEvent, titleString } from './util/utilFunctions'
import axios from 'axios'
import { MasterDscDict } from './config/dscVals'


type appConfig = {
  geo: string,
  gclid: string,
  msclkid: string,
  dscs: number[],
  dscVals: JSON,
  subid: string,
  wiwJSON: JSON | null,
  wiwid: string,
  utm_term: string,
  initialQuestion: any,
  //isCashOut: boolean,
}

class App extends Component<any, appConfig> {

  /* useless contructor
  constructor(props: any){
    super(props)
  }*/

  state: appConfig = {
    geo: '',
    gclid: 'gclid',
    msclkid: 'msclkid',
    dscs: [],
    dscVals: JSON.parse('{}'),
    subid: '',
    wiwJSON: null,
    wiwid: '',
    utm_term: '',
    initialQuestion: theQuestions.BATHROOMTYPE,
    //isCashOut: false
  }


  private setConfigVal = (cName: number, cVal: any) => {
    if (isDebug) console.log('process ' + cName + ' ' + cVal)
    switch (+cName) {
      case (configEnum.GEO): {
        this.setState({ geo: cVal })
        break
      }
      case (configEnum.GCLID): {
        this.setState({ gclid: cVal })
        break
      }
      case (configEnum.MSCLKID): {
        this.setState({ msclkid: cVal })
        break
      }
      case (configEnum.DSCS): {
        this.setState({ dscs: cVal })
        break
      }
      case (configEnum.DSCVALS): {
        this.setState({ dscVals: JSON.parse(cVal) })
        break
      }
      case (configEnum.SUBID): {
        this.setState({ subid: cVal })
        break
      }
      case (configEnum.WIWJSON): {
        this.setState({ wiwJSON: JSON.parse(cVal) })
        break
      }
      case (configEnum.WIWID): {
        this.setState({ wiwid: cVal })
        break
      }
      case (configEnum.UTMTERM): {
        this.setState({ utm_term: cVal })
        break
      }
    }
  }

  private getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    if (isDebug) console.log(query)
    var vars = query.split("&");
    if (isDebug) console.log(vars)
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (isDebug) console.log(pair)
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }


  public mountThatQueryString() {

    const dscs = this.getQueryVariable('dsc')
    let dscInitVals = ''
    if (dscs) {
      this.setConfigVal(configEnum.DSCS, this.cleanDscs(dscs))
      dscInitVals = this.getDSCS(dscs)
    } else {
      this.setConfigVal(configEnum.DSCS, '00000')
      dscInitVals = this.getDSCS('')
    }
    this.setConfigVal(configEnum.DSCVALS, dscInitVals)


    const randomValidsArray = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    let wiwid = ''
    let wCtr = 0
    while (wCtr < 8) {
      wiwid += randomValidsArray[Math.floor(Math.random() * randomValidsArray.length)];
      wCtr += 1
    }
    this.setConfigVal(configEnum.WIWID, wiwid)

    let geo = this.getQueryVariable('geo')
    const gclid = this.getQueryVariable('gclid')
    const msclkid = this.getQueryVariable('msclkid')
    const subid = this.getQueryVariable('sub_id')
    const utmTerm = this.getQueryVariable('utm_term')

    if (isDebug) console.log('geo=' + geo)
    if (!geo) {
      geo = 'UNK'
    }
    if (geo) {
      const targetUrl = campSkin.restBaseURL + '/rest/geoInfo.cgi?geo=' + geo + '&camp=' + campName
      if (isDebug) console.log(targetUrl)
      axios({
        method: 'get',
        url: targetUrl,
        responseType: 'json',
      })
        .then(res => {
          if (isDebug) console.log('WIW says: ' + JSON.stringify(res.data))
          this.setConfigVal(configEnum.WIWJSON, JSON.stringify(res.data))
          try {
            // no longer needed for how-much with serverside includes dynamically setting SEO viable titles
            const jDSCS = JSON.parse(dscInitVals)  // eslint-disable-line
            document.title = titleString(jDSCS.dsc5) + ' ' +
             titleString(jDSCS.dsc4) + ' ' +
             titleString(jDSCS.dsc1) + ' ' +
             titleString(jDSCS.dsc2) + ' ' +
             titleString(jDSCS.dsc3) //+ ' in ' + res.data.name
          } catch (e) {
            if (isDebug) console.log('DSC unpacking error: ' + e)
            //document.title = 'Bathroom Remodel Affordability in ' + res.data.name
            document.title = 'How Much Does a Bathroom Remodel Cost?'
          }
        })
      this.setConfigVal(configEnum.GEO, geo)
    }
    if (gclid) {
      this.setConfigVal(configEnum.GCLID, gclid)
    }
    if (msclkid) {
      this.setConfigVal(configEnum.MSCLKID, msclkid)
      logEvent('forBing', '', 'MSCLKID=' + msclkid, wiwid)
    }
    if (subid) {
      this.setConfigVal(configEnum.SUBID, subid)
    }
    if (utmTerm) {
      this.setConfigVal(configEnum.UTMTERM, utmTerm)
    }

  }

  private getDSCS(aInput: string): string {
    const cleanInputArray = this.cleanDscs(aInput)
    return '{"dsc1":"' + this.getSafeDSC(MasterDscDict, 'dsc1List', cleanInputArray[0]) +
      '", "dsc1Alt1":"' + this.getSafeDSC(MasterDscDict, 'dsc1ListAlt1', cleanInputArray[0]) +
      '", "dsc2":"' + this.getSafeDSC(MasterDscDict, 'dsc2List', cleanInputArray[1]) +
      '", "dsc2Alt1":"' + this.getSafeDSC(MasterDscDict, 'dsc2ListAlt1', cleanInputArray[1]) +
      '", "dsc2Alt2":"' + this.getSafeDSC(MasterDscDict, 'dsc2ListAlt2', cleanInputArray[1]) +
      '", "dsc3":"' + this.getSafeDSC(MasterDscDict, 'dsc3List', cleanInputArray[2]) +
      '", "dsc4":"' + this.getSafeDSC(MasterDscDict, 'dsc4List', cleanInputArray[3]) +
      '", "dsc5":"' + this.getSafeDSC(MasterDscDict, 'dsc5List', cleanInputArray[4]) + '"}'
  }

  private getSafeDSC(masterDict: any, dictList: string, dIndex: number): string {
    try {
      if (dIndex >= masterDict[dictList].length) dIndex = 0
      return masterDict[dictList][dIndex]
    } catch (e) {
      console.log(e)
      return masterDict[dictList][0]
    }
  }

  private cleanDscs(dirtyDSCS: string): number[] {
    let dscCleanArray = [0, 0, 0, 0, 0]
    const stripChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ|_'
    let dscClean = ''
    for (const c of dirtyDSCS) {
      if (stripChars.indexOf(c) >= 0) {
        dscClean += '-'
      } else {
        dscClean += c
      }
    }
    while (dscClean.indexOf('-') >= 0) {
      dscClean = dscClean.replace('-', '')
    }
    if (dscClean.length !== 5) {
      dscCleanArray = [0, 0, 0, 0, 0]
    } else {
      const dscCleanA = dscClean.split('')
      dscCleanArray[0] = +dscCleanA[0]
      dscCleanArray[1] = +dscCleanA[1]
      dscCleanArray[2] = +dscCleanA[2]
      dscCleanArray[3] = +dscCleanA[3]
      dscCleanArray[4] = +dscCleanA[4]
    }
    return dscCleanArray
  }



  componentDidMount = () => {
    this.mountThatQueryString()
  }

  render(): ReactElement {

    return (
      <div className="App">
        <MainFrame
          qs={window.location.search.substring(1)}
          subid={this.state.subid}
          dscs={this.state.dscs}
          dscVals={this.state.dscVals}
          wiwJSON={this.state.wiwJSON}
          wiwid={this.state.wiwid}
          gclid={this.state.gclid}
          msclkid={this.state.msclkid}
          utmTerm={this.state.utm_term}
          initialQuestion={this.state.initialQuestion}
        />
      </div>
    );
  }
}

export default App;

export const emailServiceURL = 'https://quantaloan.com/rest/v2020/email/sendLead_bathroom.cgi'
//export const SnMlistings = 'https://www.schuenkemroz.com/mls/listings'
//export const diamondDirect = 'https://apply.diamondresidential.com/#/signup?referrerId=matt.eskew%40drmconline.com&loanType=MORTGAGE'
/*export const emailTestServiceURL = 'https://quantaloan.com/rest/email/sendLeadTEST.cgi'
//export const diamondDirect = 'https://apply.diamondresidential.com/#/signup?referrerId=matt.eskew%40drmconline.com&loanType=MORTGAGE'
// temporarily in for generic testing
export const diamondDirect = 'https://apply.diamondresidential.com/#/milestones?referrerId=chris.diamond%40drmconline.com'
//export const ltDirectBase = 'https://ck.lendingtree.com/?a=385&c=1949&s1='  //gen
export const ltDirectBase = 'https://ck.lendingtree.com/?a=385&c=3192&s1='  //refi
export const creditOfferInfo = [
    ['FreeCredit360', 'From Free Credit 360', 'https://track.flexlinkspro.com/a.ashx?foid=1072339.2876124&foc=1&fot=9999&fos=1&fobs='],
    ['CreditSesame', 'From financial experts at Credit Sesame', 'https://track.flexlinkspro.com/a.ashx?foid=1072339.139231408&foc=1&fot=9999&fos=1&fobs=']
]
*/
import React, { Component } from 'react'
import { theQuestions } from '../../config/configEnums'

type MyProps = {
    handleClick(qNum: number, midVal: string, theText: string): any,
    createButton(qNum: number, theText: string, midVal: string): any,
    currentQuestion: number,
}

export class OwnHome extends Component<MyProps> {

    render() {
        const qnum = theQuestions.OWNHOME
        const iLabels = ['Yes', 'No']
        const iVals = iLabels //[1.1, 1]
        let indiButtons = <div></div>
        let aCtr = 0
        while (aCtr < iLabels.length) {
            indiButtons = <>{indiButtons} <div>{this.props.createButton(qnum, iLabels[aCtr], '' + iVals[aCtr])}
            </div></>
            aCtr++
        }
        return (
            <div id="iQowned" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>Do you own the home for this project?</b></span><br />
                {indiButtons}
            </div >
        )
    }

}

export default OwnHome

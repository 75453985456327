import React, { Component } from 'react'
import { theQuestions } from '../../config/configEnums'

type MyProps = {
    handleClick(qNum: number, midVal: string, theText: string): any,
    createButton(qNum: number, theText: string, midVal: string): any,
    currentQuestion: number,
}

export class BathroomType extends Component<MyProps> {

    /*
    render() {
        const qnum = theQuestions.BATHROOMTYPE
        const iLabels = ['Master', 'Standard', 'Half (no Shower)']
        const iVals = [15000, 7000, 4000]
        let indiButtons = <div></div>
        let aCtr = 0
        while (aCtr < iLabels.length) {
            indiButtons = <>{indiButtons} <div>{this.props.createButton(qnum, iLabels[aCtr], '' + iVals[aCtr])}
            </div></>
            aCtr++
        }
        return (
            <div id="iQtype" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>What type of bathroom are you considering?</b></span><br />
                {indiButtons}
            </div >
        )
    }*/

    // Dumb it down
    render() {
        const qnum = theQuestions.BATHROOMTYPE
        const iLabels = [
            'Bathtub/Shower Conversion',
            'Bathtub/Shower Updates',
            'Bath/Shower Liner/Enclosure',
            'Walk-In Shower',
            'Complete Bathroom Remodel'
        ]
        //const iVals = [1500, 1000, 1200, 2500, 4000]
        const iVals = [
            'BATHROOM_REFACING',
            'BATHROOM_REFACING',
            'BATHROOM_REFACING',
            'WALK_IN_SHOWERS',
            'BATH_REMODEL'
        ]
        let indiButtons = <div></div>
        let aCtr = 0
        while (aCtr < iLabels.length) {
            indiButtons = <>{indiButtons} <div>{this.props.createButton(qnum, iLabels[aCtr], '' + iVals[aCtr])}
            </div></>
            aCtr++
        }
        return (
            <div id="iQtype" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>Which of these best describe your needs?</b></span><br />
                {indiButtons}
            </div >
        )
    }

}

export default BathroomType

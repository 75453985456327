import React, { Component } from 'react'
import { theQuestions, isDebug } from '../../config/configEnums'
import { Tooltip, Icon, Radio } from 'antd'
import Ripples from 'react-ripples'
import './Phone.css'

type MyProps = {
    handlePhoneClick(phone: string, contactMethod: string): any,
    currentQuestion: number,
    isDisabledDisabledCSS: string,
    isDisabledCSS: string,
}

export class Phone extends Component<MyProps> {

    state = {
        phone: '',
        phone1: '',
        phone2: '',
        phone3: '',
        phoneSubmitted: false,
        contactMethod: 'Text',
        isDisabledDisabledCSS: this.props.isDisabledDisabledCSS,
        isDisabledCSS: this.props.isDisabledCSS,
        errMsg: 'Please Enter Your Phone Number',
    }

    phoneSubmitRef: React.RefObject<HTMLButtonElement>
    phone2Ref: React.RefObject<HTMLInputElement>
    phone3Ref: React.RefObject<HTMLInputElement>

    constructor(props: any) {
        super(props);
        this.phoneSubmitRef = React.createRef();
        this.phone2Ref = React.createRef();
        this.phone3Ref = React.createRef();
    }

    private onPhone1Change = (e: any) => {
        if (e.target.value.length === 3) {
            if (this.phone2Ref.current) {
                if (isDebug) console.log('focus to phone2')
                this.phone2Ref.current.focus()
            }
        }
        if (e.target.value.length > 3) {
            this.setState({
                phone1: '',
                phone: ''
            })
        } else {
            const newNum = e.target.value + this.state.phone2 + this.state.phone3
            this.setState({
                phone1: e.target.value,
                phone: newNum
            })
            this.checkToEnablePhoneButton(newNum)
        }
    }
    private onPhone2Change = (e: any) => {
        if (e.target.value.length === 3) {
            if (this.phone3Ref.current) {
                if (isDebug) console.log('focus to phone3')
                this.phone3Ref.current.focus()
            }
        }
        if (e.target.value.length > 3) {
            this.setState({
                phone2: '',
                phone: ''
            })
        } else {
            const newNum = this.state.phone1 + e.target.value + this.state.phone3
            this.setState({
                phone2: e.target.value,
                phone: newNum
            })
            this.checkToEnablePhoneButton(newNum)
        }
    }
    private onPhone3Change = (e: any) => {
        if (e.target.value.length > 4) {
            this.setState({
                phone3: '',
                phone: this.state.phone1 + this.state.phone2
            })
        } else {
            this.setState({
                phone3: e.target.value,
                phone: this.state.phone1 + this.state.phone2 + e.target.value
            })
        }
        const newNum = this.state.phone1 + this.state.phone2 + e.target.value
        this.checkToEnablePhoneButton(newNum)
    }

    private checkToEnablePhoneButton(thisPhone: string) {
        if (isDebug) { console.log('checking ' + thisPhone) }
        if (this.isValidPhone(thisPhone)) {
            this.setState({ isDisabledCSS: '', isDisabledDisabledCSS: 'hiddenItem' })

            // the time delay here is to get focus working as expected
            setTimeout(() => {
                if (this.phoneSubmitRef.current) {
                    if (isDebug) console.log('Valid Phone input. Submit Button Focused')
                    this.phoneSubmitRef.current.focus()
                }
            }, 150);

        } else {
            this.setState({ isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '' })
        }
    }
    private cleanPhoneNumber(dirtyPhone: string): string {
        const stripChars = ' abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~!@#$%^&*()_-+=`"\';:?/.,<>'
        let cleanPhone = ''
        for (const c of dirtyPhone) {
            if (stripChars.indexOf(c) >= 0) {
                // do nothing
            } else {
                cleanPhone += c
            }
        }
        if (isDebug) console.log('Cleaned Phone = ' + cleanPhone)
        return cleanPhone
    }
    private isValidPhone(thisPhone: string): boolean {
        let phone: string = this.cleanPhoneNumber(thisPhone)
        if (isDebug) console.log('phone length=' + phone.length)
        if (phone.length !== 10) {
            this.setState({ errMsg: 'Please enter your 10 digit phone number starting with the area code.' })
            return false
        }

        if (phone.substr(0, 1) === '0') {
            this.setState({
                errMsg: "Sorry.  Valid phone numbers do not have area codes that start with a zero.  Please correct your phone number and try again.  Your phone number is for confirmation purposes only."
            })
            return false
        }
        if (phone.substr(0, 1) === '1') {
            this.setState({
                errMsg: "Sorry.  Valid phone numbers do not have area codes that start with a #1.  Please correct your phone number and try again.  Your phone number is for confirmation purposes only."
            })
            return false
        }

        try {
            if (phone.substr(3, 4) === '1') {
                this.setState({
                    errMsg: "Sorry, valid phone numbers can not have a local code that starts with a #1. Please correct your phone number and try again.  Your phone number is for confirmation purposes only."
                })
                return false
            }
        } catch (ex) { }

        const areaCode = phone.substr(0, 3)
        const localCode = phone.substr(3, 3)
        if (isDebug) console.log('Check area and local phone codes: ' + areaCode + ' ' + localCode)

        const badAreas = ["111", "211", "311", "411", "511",
            "911", "800", "552", "553", "554", "555", "556"]
        if (badAreas.indexOf(areaCode) >= 0) {
            this.setState({
                errMsg: "This area code doesnt look right. Please correct your phone number and try again.  Your phone number is for confirmation purposes only."
            })
            return false
        }
        const badLocals = ["555", "111", "123", "211", "311",
            "411", "511", "611", "711", "811", "911"]
        if (badLocals.indexOf(localCode) >= 0) {
            this.setState({
                errMsg: "The local code doesnt look right. Please correct your phone number and try again.  Your phone number is for confirmation purposes only."
            })
            return false
        }
        if (localCode.substr(0, 1) === '0') {
            this.setState({
                errMsg: "Sorry, valid phone numbers can not start with a zero. Please correct your local number and try again.  Your phone number is for confirmation purposes only."
            })
            return false
        }
        if (localCode.substr(0, 1) === '1') {
            this.setState({
                errMsg: "Sorry, valid phone numbers can not start with a one. Please correct your local number and try again.  Your phone number is for confirmation purposes only."
            })
            return false
        }
        return true
    }

    private setMethodText = () => {
        this.setState({ contactMethod: 'Text' })
    }
    private setMethodCall = () => {
        this.setState({ contactMethod: 'Call' })
    }

    private handlePhoneClickTest(phone: string, method: string) {
        alert('test: ' + phone + ' ~ ' + method)
    }

    render() {
        const theButtonText = 'Get Estimate'
        let continueLabel = <>{theButtonText.toUpperCase()} &nbsp; <Icon type="double-right" /></>
        if (this.state.phoneSubmitted) {
            continueLabel = <>Submitting ...</>
        }
        const qnum = theQuestions.PHONE
        return (
            <div id="iPHONE" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                {isDebug ? <span style={{ color: 'red' }}>isDisabled = {this.state.isDisabledCSS}<br></br></span> : ''}
                <span className="questionTitle"><b>What Is Your Phone Number?</b></span><br />
                <div className="container">
                    <div className='pNumbers'>
                        ( <input
                            type="number"
                            value={this.state.phone1}
                            className="textInputStylePhone12"
                            maxLength={3}
                            placeholder="###"
                            key="userPhone1"
                            onChange={this.onPhone1Change} /> ) &nbsp;
                <input
                            ref={this.phone2Ref}
                            value={this.state.phone2}
                            type="number"
                            className="textInputStylePhone12"
                            maxLength={3}
                            placeholder="###"
                            key="userPhone2"
                            onChange={this.onPhone2Change} />&nbsp;-&nbsp;
                <input
                            ref={this.phone3Ref}
                            value={this.state.phone3}
                            type="number"
                            className="textInputStylePhone3"
                            maxLength={4}
                            placeholder="####"
                            key="userPhone3"
                            onChange={this.onPhone3Change} />
                    </div>
                </div>
                <p></p>
                <b>Preferred Contact Method</b><br></br>
                <span style={{ fontSize: '12px' }}>Important: Your remodel estimate is confidential and proprietary.  Your information needs to be confirmed.</span>
                <br></br>
                <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1} onChange={this.setMethodText}>Text</Radio> &nbsp;
                    <Radio value={2} onChange={this.setMethodCall}>Call</Radio>
                </Radio.Group>
                <p></p>
                <Tooltip className={'tooltip-inner'} title={this.state.errMsg} placement="bottom">
                    <Ripples color="#a90000">
                        <button disabled
                            className={'ha-button-continue ' + this.state.isDisabledDisabledCSS}
                        >
                            <p style={{ textAlign: 'center', lineHeight: '56px', color: '#dadada' }}> {continueLabel} </p>
                        </button>
                    </Ripples>
                </Tooltip>
                <Ripples color="#00ff00">
                    <button
                        ref={this.phoneSubmitRef}
                        className={'ha-button-continue ' + this.state.isDisabledCSS}
                        onClick={() => {
                            this.props.handlePhoneClick(this.state.phone, this.state.contactMethod)
                            this.setState({
                                phoneSubmitted: true,
                                isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: ''
                            })
                        }}
                    >
                        <p style={{ textAlign: 'center', lineHeight: '56px' }}> {continueLabel} </p>
                    </button>
                </Ripples>
                <p></p>
                <p></p>
                <p className="tcpaText">
                    We respect your privacy and want to make sure you are aware of a few things.  By Submitting
                    you authorize QuinStreet, Home Advisor <a href="https://legal.homeadvisor.com/#privacy-policy"
                        rel="noopener noreferrer"
                        target="_blank">Privacy</a> &amp; <a href="https://legal.homeadvisor.com/#privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer">Terms</a>, and up to four home improvement companies to call you on the
                    mobile or landline phone number provided to discuss your project.  You understand that some may
                    use automated phone technology including text messaging to contact you and that you are
                    in no way required to purchase any products or services from them.  It's entirely your choice.
                  </p>
            </div>
        )

    }

}

export default Phone

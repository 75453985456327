import React, { Component } from 'react'
import { theQuestions } from '../../config/configEnums'

type MyProps = {
    handleClick(qNum: number, midVal: string, theText: string): any,
    createButton(qNum: number, theText: string, midVal: string): any,
    currentQuestion: number,
}

export class BathroomTiles extends Component<MyProps> {

    render() {
        const qnum = theQuestions.BATHROOMTILES
        const iLabels = ['Floors', 'Floors and Walls', 'None'] 
        const iVals = [1, 1.1, 0.75]
        let indiButtons = <div></div>
        let aCtr = 0
        while (aCtr < iLabels.length) {
            indiButtons = <>{indiButtons} <div>{this.props.createButton(qnum, iLabels[aCtr], '' + iVals[aCtr])}
            </div></>
            aCtr++
        }
        return (
            <div id="iQtiles" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>Where do you want tiling?</b></span><br />
                {indiButtons}
            </div >
        )
    }

}

export default BathroomTiles

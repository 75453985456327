export const campName = 'bathroom'

export enum configEnum {
  GEO,
  GCLID,
  MSCLKID,
  DSCS,
  DSCVALS,
  SUBID,
  WIWJSON,
  WIWID,
  ISIL,
  UTMTERM,
}

export enum theQuestions {
  /* Standard Qs */
  NAME,
  EMAIL,
  PHONE,
  /* Quinstreet specific Qs */
  BATHROOMTYPE, // introducing new horizons in home improvement
  BATHROOMTILES,
  BATHROOMWALLS,
  BATHROOMSHOWER,
  BATHROOMQUALITY,
  OWNHOME,
  HOWSOON,
  STREETADDRESSZIP,
  BESTTIME,
  /* unused but kept for compilation */
  INCOME,
  CREDIT,
  VA,
  INTENT,
  DOWNPAYMENT,
  BANKRUPTCYTYPE,
  HOWLONG,
  EXIT, 
  LOANTYPE,
  LOANAMOUNT,
  LOWCREDIT,
  PROPERTYUSE,
  LOANPURPOSE,
  PROPERTYDESC,
  REASONFORBADCREDIT,  // NEW in SE
  SELFEMPLOYEDLENGTH,
  SELFEMPLOYEDREVENUE,
  SELFEMPLOYEDBUSINESS,
  PURCHASEORREFI,
  TURNEDDOWN,
  REASONTURNEDDOWN,
  REFIGOAL,
  HASFHA,
  CURRENTRATE,
  CURRENTLOAN,
  HOMEVALUE,
  MONTHLYDEBT,
  AUTODEBT,
  STUDENTDEBT,
  CHILDDEBT,
  CREDITCARDDEBT,
  PURCHASESTATE,
  EMPLOYMENTSTATUS,
  TARGETPRICE,
  WHICHISLAND,
  PREQUALED
}


export const currentMedianHouseholdIncome = 63553
export const previousMedianHouseholdIncome = 53046.0
export const iFactor = (currentMedianHouseholdIncome / previousMedianHouseholdIncome)


/* property data from
# http://www.tax-rates.org/taxtables/property-tax-by-state
# income from https://www.money-zine.com/financial-planning/tax-shelter/state-income-tax-rates/
# sales and max local surtax from https://en.wikipedia.org/wiki/Sales_taxes_in_the_United_States
# property, max income, baseStateSales, maxLocalSurtax */
export const taxesDict: any = {
  'AL': [0.33, 5.00, 4.0, 13.5],
  'AK': [1.04, 0.00, 0, 7],
  'AZ': [0.72, 4.54, 5.6, 10.73],
  'AR': [0.52, 6.90, 6.5, 11.63],
  'CA': [0.74, 13.3, 7.25, 10.25],
  'CT': [1.63, 6.99, 6.35, 6.35],
  'CO': [0.60, 4.63, 2.9, 10],
  'DE': [0.43, 6.60, 0, 0],
  'FL': [0.97, 0.00, 6, 7.5],
  'GA': [0.83, 6.00, 4, 8],
  'HI': [0.26, 11.0, 4.17, 4.71],
  'ID': [0.69, 7.40, 6, 8.5],
  'IL': [1.73, 4.95, 6.25, 10.25],
  'IN': [0.85, 3.23, 7, 7],
  'IA': [1.29, 8.98, 6, 7],
  'KS': [1.29, 5.70, 6.5, 10.25],
  'KY': [0.72, 6.00, 6, 6],
  'LA': [0.18, 6.00, 5, 12],
  'ME': [1.09, 7.15, 5.5, 5.5],
  'MD': [0.87, 5.75, 6, 6],
  'MA': [1.04, 5.10, 6.25, 6.25],
  'MI': [1.62, 4.25, 6, 6],
  'MN': [1.05, 9.85, 6.88, 7.88],
  'MS': [0.52, 5.00, 7, 7.25],
  'MO': [0.91, 5.90, 4.23, 10.85],
  'MT': [0.83, 6.90, 0, 0],
  'NE': [1.76, 6.84, 5.5, 7.5],
  'NV': [0.84, 0.00, 6.85, 8.25],
  'NH': [1.86, 5.00, 0, 0],
  'NJ': [1.89, 8.97, 6.63, 12.88],
  'NM': [0.55, 4.90, 5.13, 8.69],
  'NY': [1.23, 8.82, 4, 8.69],
  'NC': [0.78, 5.50, 4.75, 7.50],
  'ND': [1.42, 2.90, 5, 8],
  'OH': [1.36, 5.00, 5.75, 8],
  'OK': [0.74, 5.00, 4.5, 11],
  'OR': [0.87, 9.90, 0, 0],
  'PA': [1.35, 3.07, 6, 8],
  'RI': [1.35, 5.99, 7, 7],
  'SC': [0.50, 7.00, 6, 9],
  'SD': [1.28, 0.00, 4, 6],
  'TN': [0.68, 3.00, 7, 9.75],
  'TX': [1.81, 0.00, 6.25, 8.25],
  'UT': [0.60, 5.00, 5.95, 8.35],
  'VT': [1.59, 8.95, 6, 7],
  'VA': [0.74, 5.75, 5.3, 6],
  'WA': [0.92, 0.00, 6.5, 10.4],
  'WV': [0.49, 6.50, 6, 7],
  'WI': [1.76, 7.65, 5, 6.75],
  'WY': [0.58, 0.00, 4, 6],
  'DC': [0.46, 8.95, 5.75, 5.75]
}


export const stateDictReversed: any = {
  //undefined: ['',''],
  'Alabama': ['AL', '01'],
  'Alaska': ['AK', '02'],
  'Arizona': ['AZ', '04'],
  'Arkansas': ['AR', '05'],
  'California': ['CA', '06'],
  'Colorado': ['CO', '08'],
  'Connecticut': ['CT', '09'],
  'Delaware': ['DE', '10'],
  'District of Columbia': ['DC', '11'],
  'Florida': ['FL', '12'],
  'Georgia': ['GA', '13'],
  'Hawaii': ['HI', '15'],
  'Idaho': ['ID', '16'],
  'Illinois': ['IL', '17'],
  'Indiana': ['IN', '18'],
  'Iowa': ['IA', '19'],
  'Kansas': ['KS', '20'],
  'Kentucky': ['KY', '21'],
  'Louisiana': ['LA', '22'],
  'Maine': ['ME', '23'],
  'Maryland': ['MD', '24'],
  'Massachusetts': ['MA', '25'],
  'Michigan': ['MI', '26'],
  'Minnesota': ['MN', '27'],
  'Mississippi': ['MS', '28'],
  'Missouri': ['MO', '29'],
  'Montana': ['MT', '30'],
  'Nebraska': ['NE', '31'],
  'Nevada': ['NV', '32'],
  'New Hampshire': ['NH', '33'],
  'New Jersey': ['NJ', '34'],
  'New Mexico': ['NM', '35'],
  'New York': ['NY', '36'],
  'North Carolina': ['NC', '37'],
  'North Dakota': ['ND', '38'],
  'Ohio': ['OH', '39'],
  'Oklahoma': ['OK', '40'],
  'Oregon': ['OR', '41'],
  'Pennsylvania': ['PA', '42'],
  'Rhode Island': ['RI', '44'],
  'South Carolina': ['SC', '45'],
  'South Dakota': ['SD', '46'],
  'Tennessee': ['TN', '47'],
  'Texas': ['TX', '48'],
  'Utah': ['UT', '49'],
  'Vermont': ['VT', '50'],
  'Virginia': ['VA', '51'],
  'Washington': ['WA', '53'],
  'West Virginia': ['WV', '54'],
  'Wisconsin': ['WI', '55'],
  'Wyoming': ['WY', '56'],
}

export const stateDict: any = {
  'RI': ['RhodeIsland', '44', 'Rhode Islander,Rhodian'],
  'MS': ['Mississippi', '28', 'Mississippian,Mississipper'],
  'NY': ['NewYork', '36', 'New Yorker,Empire Stater'],
  'OK': ['Oklahoma', '40', 'Oklahoman,Oklahomian,Okie,Sooner'],
  'DE': ['Delaware', '10', 'Delawarean,Muskrat'],
  'MN': ['Minnesota', '27', 'Minnesotan'],
  'IL': ['Illinois', '17', 'Illinoisan,Illinoian,Illinoyer,Illini'],
  'AR': ['Arkansas', '05', 'Arkansan,Arkie,Arkansawyer'],
  'IN': ['Indiana', '18', 'Indianian,Indianer,Hoosier'],
  'LA': ['Louisiana', '22', 'Louisianian,Louisianan,Cajun'],
  'TX': ['Texas', '48', 'Texan,Texian,Tejano'],
  'NH': ['NewHampshire', '33', 'New Hampshirite,New Hampshireman,Granite Stater'],
  'SD': ['SouthDakota', '46', 'South Dakotan'],
  'KS': ['Kansas', '20', 'Kansan,Kanser,Jayhawk'],
  'CT': ['Connecticut', '09', 'Connecticuter,Connecticotian,Connecticutian,Nutmegger'],
  'CA': ['California', '06', 'Californian,Californiac,Californio'],
  'GA': ['Georgia', '13', 'Georgian'],
  'PA': ['Pennsylvania', '42', 'Pennsylvanian,Pennamite'],
  'AK': ['Alaska', '02', 'Alaskan,Ice Chipper'],
  'MO': ['Missouri', '29', 'Missourian'],
  'CO': ['Colorado', '08', 'Coloradan,Coloradoan'],
  'WA': ['Washington', '53', 'Washingtonian'],
  'NV': ['Nevada', '32', 'Nevadan,Nevadian'],
  'IA': ['Iowa', '19', 'Iowan,Hawkeye,Iowegian'],
  'DC': ['DistrictOfColumbia', '11', 'Washingtonian'],
  'SC': ['SouthCarolina', '45', 'South Carolinian,South Carolinan,Sandlapper'],
  'MD': ['Maryland', '24', 'Marylander,Marylandian'],
  'ID': ['Idaho', '16', 'Idahoan,Idahoer'],
  'WY': ['Wyoming', '56', 'Wyomingite,Wyomingian,Wyoman'],
  'AZ': ['Arizona', '04', 'Arizonan,Arizonian,Sand Cutter'],
  'WI': ['Wisconsin', '55', 'Wisconsinite,Cheesehead,Badger'],
  'MI': ['Michigan', '26', 'Michiganian,Michigander,Michiganese,Michigine,Michiganite,Wolverine'],
  'UT': ['Utah', '49', 'Utahn,Utahan'],
  'VA': ['Virginia', '51', 'Virginian'],
  'OR': ['Oregon', '41', 'Oregonian,Oregoner'],
  'MT': ['Montana', '30', 'Montanan'],
  'MA': ['Massachusetts', '25', 'Massachusettsan,Bay Stater,Massachusite'],
  'NM': ['NewMexico', '35', 'New Mexican'],
  'VT': ['Vermont', '50', 'Vermonter'],
  'NC': ['NorthCarolina', '37', 'North Carolinian,Tar Heel'],
  'FL': ['Florida', '12', 'Floridian,Floridan'],
  'HI': ['Hawaii', '15', 'Hawaiian,Malihini,Islander'],
  'KY': ['Kentucky', '21', 'Kentuckian,Kentucker,Kentuckeyite'],
  'NE': ['Nebraska', '31', 'Nebraskan,Cornhusker'],
  'WV': ['WestVirginia', '54', 'West Virginian,Mountaineer'],
  'OH': ['Ohio', '39', 'Ohioan,Buckeye'],
  'AL': ['Alabama', '01', 'Alabamian'],
  'NJ': ['NewJersey', '34', 'New Jerseyan,New Jerseyite,Jerseyite'],
  'TN': ['Tennessee', '47', 'Tennessean,Volunteer,Big Bender,Butternut'],
  'ND': ['NorthDakota', '38', 'North Dakotan,NoDak'],
  'ME': ['Maine', '23', 'Mainer,Mainiac,Down Easter']
}


// TODO make this switch automatic and hidden
export const campSkin =
{
  restBaseURL: 'https://quantaloan.com',
  siteBaseURL: 'https://homequote.org',
  siteName: 'HomeQuote',
  siteContact: 'info@homequote.org',
  feedbackContact: 'feedback@homequote.org',
  logURL: 'https://quantaloan.com/rest/logging/logReact.cgi',
  qTableBase: 'https://quantaloan.com/qTable2019.cgi',
  buttonClickColor: '#00ff00',
  logoImage: 'bathroom-icon.png',
}
/*
export const campSkin =
{
  restBaseURL: 'https://quantaloan.com',
  siteBaseURL: 'https://fhalendersnetwork.com',
  siteName: 'HomeQuote',
  siteContact: 'matt@wiwsem.com',
  feedbackContact: 'matt@wiwsem.com',
  logURL: 'https://quantaloan.com/rest/logging/logReact.cgi',
  qTableBase: 'https://quantaloan.com/qTable2019.cgi',
  buttonClickColor: '#00ff00',
  logoImage: 'bathroom-icon.png',
}*/

// TODO rip out
export const minSavings: number = 50
export const minCashOutSavings: number = 5000
export const frontEndRatio: number = .33
export const backendRatio: number = .4

export const isDebug: boolean = false
export const isDebugLogging: boolean = false

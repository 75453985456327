import React, { Component } from 'react'
import { theQuestions, isDebug } from '../../config/configEnums'
import { Input, Tooltip, Icon } from 'antd'
import Ripples from 'react-ripples'
import './Email.css'

type MyProps = {
    handleEmailClick(email: string): any,
    currentQuestion: number,
    isDisabledDisabledCSS: string,
    isDisabledCSS: string,
}

export class Email extends Component<MyProps> {

    state = {
        email: '',
        isDisabledDisabledCSS: this.props.isDisabledDisabledCSS,
        isDisabledCSS: this.props.isDisabledCSS,
    }


    private onEmailChange = (e: any) => {
        this.setState({ email: e.target.value })
        this.checkToEnableEmailButton(e.target.value)
    }
    private checkToEnableEmailButton(thisEmail: string) {
        if (isDebug) { console.log('checking ' + thisEmail) }
        if (this.isValidEmail(thisEmail)) {
            this.setState({ isDisabledCSS: '', isDisabledDisabledCSS: 'hiddenItem' })
        } else {
            this.setState({ isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '' })
        }
    }
    private isValidEmail(anEmail: string): boolean {
        // replace with regex
        if (anEmail.indexOf('@') < 2) return false;
        if (anEmail.indexOf(' ') > 0) return false;
        if (anEmail.indexOf('!') > 0) return false;
        if (anEmail.indexOf('#') > 0) return false;
        if (anEmail.indexOf('$') > 0) return false;
        if (anEmail.indexOf('^') > 0) return false;
        if (anEmail.indexOf('&') > 0) return false;
        if (anEmail.indexOf('*') > 0) return false;
        if (anEmail.indexOf('(') > 0) return false;
        if (anEmail.indexOf(')') > 0) return false;
        if (anEmail.indexOf('@') !== anEmail.lastIndexOf('@')) return false;
        if (anEmail.lastIndexOf('.') < (anEmail.indexOf('@') + 2)) return false;
        if (anEmail.lastIndexOf('.') > (anEmail.length - 3)) return false;
        if (anEmail.length < 10) return false;
        return true
    }


    render() {
        const qnum = theQuestions.EMAIL

        return (
            <div id="iEMAIL" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>What Is Your Email?</b></span><br />
                <Input className="emailInputStyle"
                    maxLength={50}
                    placeholder="you@example.com"
                    key="userEmail"
                    onChange={this.onEmailChange} />
                <p></p>
                <Tooltip title="Please Enter a Valid Email" placement="bottom">
                    <Ripples color="#a90000">
                        <button disabled
                            className={'ha-button-continue ' + this.state.isDisabledDisabledCSS}
                        >
                            <p style={{ textAlign: 'center', lineHeight: '56px', color: '#dadada' }}> CONTINUE &nbsp; <Icon type="double-right" /> </p>
                        </button>
                    </Ripples>
                </Tooltip>
                <Ripples color="#00ff00">
                    <button
                        className={'ha-button-continue ' + this.state.isDisabledCSS}
                        onClick={() => this.props.handleEmailClick(this.state.email)}>
                        <p style={{ textAlign: 'center', lineHeight: '56px' }}> CONTINUE &nbsp; <Icon type="double-right" /> </p>
                    </button>
                </Ripples>
<p></p>
<b>Important: Your bathroom estimate information will be securely delivered to your email. We <u>never</u> share your email address.</b>

            </div>
        )

    }

}

export default Email

export const MasterDscDict = {
    'dsc1List': ["bathroom",
        "bathroom",
        "bathroom"],
    'dsc1ListAlt1': ["bathroom",
        "bathroom",
        "bathroom"],
    'dsc2List': ["",
        "remodel",
        "redo",
        "renovate",
        "renovation",
        "demo",
        "addition",
        "refurbish"],
    'dsc2ListAlt1': ["",
        "remodel",
        "redo",
        "renovate",
        "renovation",
        "demo",
        "addition",
        "refurbish"],
    'dsc2ListAlt2': ["",
        "remodel",
        "redo",
        "renovate",
        "renovation",
        "demo",
        "addition",
        "refurbish"],
    'dsc3List': ["", "cost", "money"],
    'dsc4List': ["", "estimate", "calculate"],
    'dsc5List': ["", "how much"],
    'decoration': "TEST",
    'created': "2020-08-24 10:31:52"
}

import React from 'react'
import "antd/dist/antd.css";
import "./PrimaryOfferModal.css"
//import { translateCredit } from '../util/utilFunctions'
import { isDebug, minSavings, frontEndRatio, backendRatio } from '../config/configEnums'
import { formatDollars } from '../util/utilFunctions';
//import { formatDollars } from '../util/utilFunctions';

export function getCurrentRate(credit: number) {
    // keeping it informational now, we can ignore the credit value
    return 4.0
}
// Determine the base rate to use for savings calculation
export function XgenerateRefiRate(credit: number) {
    let newRate = 5.25
    if (credit > 500) {
        newRate = 5.0
    }
    if (credit > 619) {
        newRate = 4.0
    }
    if (credit > 659) {
        newRate = 3.5
    }

    //if (credit === 0) {
    if (credit > 739) {
        newRate = 3.25
    }
    //if (credit === 2) {
    //    return 3.5
    //}
    if (isDebug) {
        console.log('CREDIT of ' + credit + ' gives new rate of ' + newRate)
    }
    return newRate  // make visual studio happy
}

// for legal compliance, we need to show the APR
export function generateRefiAPREstimate(credit: number,
    currentLoanAmount: number): number {

    const newRate = XgenerateRefiRate(credit) / 100
    /*
    // maybe a better way to calculate APR?
    const monthlyPI = currentLoanAmount /
        ((Math.pow((1 + (newRate / 12)), 360) - 1) / ((newRate / 12) * Math.pow((1 + (newRate / 12)), 360)))
    if (isDebug) console.log('calculating APR from initial rate : monthly PI ' + newRate +
        '% : ' + monthlyPI)
        */
    // now solve for APR
    //const loanPlusFees = currentLoanAmount + 1500
    //monthlyPI = loanMinusFees / ((Math.pow((1 + (aprRate / 12)), 360) - 1) / ((aprRate / 12) * Math.pow((1 + (aprRate / 12)), 360)))

    // from https://www.lexingtonlaw.com/credit/what-is-apr  "Note that numbers may vary based on how you calculate APR."
    const aprRate = (newRate * 100) + (((1500 / currentLoanAmount) / (365 * 30)) * 365) * 230 //100?
    return +aprRate.toFixed(2)
}

export function generateBEHAMonthlyEstimate(
    monthlyDebtInDollars: number,
    income: number): number {
    const monthlyIncome = income / 12
    const maxPercentAvailable = backendRatio - (monthlyDebtInDollars / monthlyIncome)
    const maxMonthlyPayment = round5(monthlyIncome * maxPercentAvailable)
    if (isDebug) {
        console.log('MAX BE monthly payment is ' + maxMonthlyPayment + ' using monthly debt of $' + monthlyDebtInDollars +
        ' and maxPercentAvailable = ' + maxPercentAvailable)
    }
    return maxMonthlyPayment
}

export function generateHAMonthlyEstimate(
    monthlyDebtInDollars: number,
    income: number): number {
    const monthlyIncome = income / 12
    //const monthlyDebtInDollars = income * (monthlyDebtInPercent / 100)
    //const maxPercentAvailable = (.40 - (((monthlyDebt / 100) * monthlyIncome) / monthlyIncome) / 100)
    // removing taxes
    //const taxedMonthlyIncome = monthlyIncome * .32 // TODO vary per state
    //WTF? const maxPercentAvailable = (.40 - (((taxedMonthlyIncome / 100) * taxedMonthlyIncome) / taxedMonthlyIncome) / 100)
    const maxPercentAvailable = frontEndRatio //.40 //* (taxedMonthlyIncome / 12)
    //const maxMonthlyPayment = round5((monthlyIncome * maxPercentAvailable) - (monthlyDebtInPercent / 100))
    const maxMonthlyPayment = round5((monthlyIncome * maxPercentAvailable) - monthlyDebtInDollars)
    if (isDebug) {
        console.log('MAX monthly payment is ' + maxMonthlyPayment + ' using monthly debt of $' + monthlyDebtInDollars)
    }
    return maxMonthlyPayment
}
export function generateHAEstimate(
    monthlyAvailable: number,
    downpayment: number,
    credit: number): number {
    const taxAndInsurance = .32 // adjust by state?
    const monthlyPI = monthlyAvailable - (taxAndInsurance * monthlyAvailable)
    const theRate = getCurrentRate(credit) / 100
    const i = theRate / 12
    const totallyAffordable = (monthlyPI / i) * (1 - Math.pow((1 + i), -360))
    //TODO add %DP ?
    const theDP = (downpayment / 100) * totallyAffordable
    if (isDebug) {
        console.log('STARTING with monthlyAvailable of ' + monthlyAvailable + ', %DP = ' + downpayment +
            ' gives a downpayment of ' + theDP + ', monthly PI ' + monthlyPI +
            ' and theRate of i= ' + i +
            ' equals total affordability of $' + totallyAffordable +
            ' plus DP and round50 for ' + round50(totallyAffordable + theDP)
        )
    }
    return round100(totallyAffordable + theDP)
}


export function generateHAEstimateText(
    downpayment: number,
    credit: number,
    monthlyDebt: number,
    income: number) {

    const theMonthlyPI = generateHAMonthlyEstimate(monthlyDebt, income)
    const theTotal = generateHAEstimate(theMonthlyPI, downpayment, credit)
    let theMessage = <>Based on your inputs, we estimate you can afford a total monthly payment
     of ${formatDollars(theMonthlyPI)} which should be able to qualify your for a total loan amount
     of ${formatDollars(theTotal)}</>

    let debugInfo = <></>
    if (isDebug) {
        debugInfo = <span style={{ color: 'red' }}>
            monthlyRAW={monthlyDebt},
            monthlyPI={theMonthlyPI},
            est={theTotal},
        credit={credit},
        income={income}
        </span>
    }

    return (<>{debugInfo}
        <p></p>
        {theMessage}
    </>)
    //}
}




export function generateRefiEstimate(
    credit: number,
    liveRate: number,
    currentLoanRate: number,
    currentLoanAmount: number): number {

    // for now we're ignoring the live rate

    const currentLoanRateSlider = currentLoanRate / 100
    const currentMonthly = currentLoanAmount /
        ((Math.pow((1 + (currentLoanRateSlider / 12)), 360) - 1) / ((currentLoanRateSlider / 12) * Math.pow((1 + (currentLoanRateSlider / 12)), 360)))

    const theRate = XgenerateRefiRate(credit)
    const newRate = theRate / 100
    const newMonthly = currentLoanAmount /
        ((Math.pow((1 + (newRate / 12)), 360) - 1) / ((newRate / 12) * Math.pow((1 + (newRate / 12)), 360)))
    if (isDebug) console.log('monthly payments at newRate of ' + newRate +
        '% change from $' + currentMonthly + ' to $' + newMonthly + ' using old rate of ' + currentLoanRate)
    return currentMonthly - newMonthly
}

function round5(x: number) {
    return Math.floor(x / 5) * 5
}
export function round50(x: number) {  // used in MainFrame
    return Math.floor(x / 50) * 50
}
export function round500(x: number) {  // used in MainFrame
    return Math.floor(x / 500) * 500
}
export function round100(x: number) {
    return Math.floor(x / 100) * 100
}
export function round1000(x: number) {
    return Math.floor(x / 1000) * 1000
}

export function generateRefiEstimateText(homeValue: number,
    showDebug: boolean,
    theEstimate: number,
    refiReason: string,
    credit: number,
    loanAmount: number) {

    let theMessage = <>You may not see significant monthly savings in the current lending environment.</>
    if (theEstimate > minSavings && credit > 649) theMessage = <>
        By refinancing, you should save over <span style={{ fontWeight: 'bold', color: 'green' }}>${round5(theEstimate)}</span> a month.
        Depending on additional factors, you maybe also be able to lower your monthly payment by taking advantage of current low interest rates.
    </>

    let orGetCashOutMsg = ''


    // refi goal iVals = ['Cash Out', 'Lower Payments', 'Shorten Loan Term', 'Other']
    let theReason = 'lower your monthly payments.'
    if (refiReason === 'Pay off mortgage faster') theReason = 'pay off your mortgage quicker.'
    if (refiReason === 'Take cash out of home') theReason = ' get cash out of your home.'
    let theGoalText = 'Potential monthly savings along with equity built into the property may be used to ' + theReason

    if (isDebug) console.log('refiReason=' + refiReason + ', theEstimate = ' + theEstimate)
    if (refiReason === 'Lower monthly payment') {  // TODO change to enum
        theGoalText = 'This monthly savings along with equity in your property may be used to lower your monthly payment.'
        if (credit < 650 || theEstimate < minSavings) {
            //theMessage = <>You may not see significant monthly savings in the current lending environment.</>
            theMessage = <>{theMessage} We need more information to calculate your savings.</>
            //theGoalText = 'However, there may still be opportunities to lower your monthly payments depending on your mortgage details.'
            theGoalText = 'There may still be opportunities to lower your monthly payments ' + orGetCashOutMsg + ' depending on your mortgage details.  '// + theGoalText
        }
    }
    if (refiReason === 'Pay off mortgage faster') {
        theGoalText = 'This monthly savings along with equity in your property may be used to shorten your loan term.'
        if (credit < 650 || theEstimate < minSavings) {
            theMessage = <>{theMessage} We need more information to calculate your savings.</>
            theGoalText = 'There may still be opportunities to pay off your mortgage faster ' + orGetCashOutMsg + ' depending on your mortgage details.'
        }
    }
    if (refiReason === 'Take cash out of home' || refiReason === 'Other') {
        theGoalText = 'This monthly savings along with equity in your property may be used to for a cash-out refinance.'
        if (credit < 650 || theEstimate < minSavings) {
            theMessage = <>{theMessage} We need more information to calculate your savings.</>
            theGoalText = 'There may still be opportunities to get cash depending on your mortgage details.'
            // ME suggested text: Potential monthly savings along with equity built in property may be used to for a {{purpose}}
            // doesnt really work
        }
    }

    let debugInfo = <></>
    if (isDebug && showDebug) {
        debugInfo = <span style={{ color: 'red' }}>
            {refiReason},
            {theEstimate},
        {refiReason},
        {credit},
        {loanAmount}
        </span>
    }

    return (<>{debugInfo}
        <p></p>
        {/*Refinancing at today's historically low rates could save
        you <span style={{ fontWeight: 'bold', color: 'green' }}>${formatDollars(getLow(theEstimate))}</span> to <span style={{ fontWeight: 'bold', color: 'green' }}>${formatDollars(getHigh(theEstimate))}</span> per month! {/* over
        the life of your loan.*/}
        {theMessage}
        <p></p>
        {theGoalText}
    </>)
    //}
}

export function generateGoodNews(refiGoal: string, credit: string,
    finalDisplay: boolean, thisState: string, loNameInfo: any): any {
    if (isDebug) console.log('c=' + credit + ', hasRefi=' +
        ', ' + refiGoal + ', ' +
        finalDisplay + ', ' + thisState)
    let itsGood = <><span style={{ color: 'green', fontSize: '22px' }}>Good News!</span><br></br><p></p></>
    if (credit === '4') {
        itsGood = <></>
    }
    let isStreamlined = 'Streamline'
    /*if (hasFHA === 'No') {
        isStreamlined = ''
    }*/
    if (refiGoal === 'Cash Out') {
        isStreamlined = 'Cash-Out'
    }

    let theBenefit = refiGoal.toLowerCase()
    if (refiGoal === 'Browse mortgage rates') {
        theBenefit = 'benefit'
    }
    if (refiGoal === 'Shorten Loan Term') {
        theBenefit = 'shorten your loan term'
    }
    if (refiGoal === 'Convert Rate Type') {
        theBenefit = 'convert your rate type'
    }
    if (refiGoal === 'Pay off mortgage faster') {
        theBenefit = 'pay off your mortgage faster'
    }
    if (refiGoal === 'Lower monthly payment') {
        theBenefit = 'lower your monthly payment'
    }
    if (refiGoal === 'Take cash out of home') {
        theBenefit = 'take cash out of your home'
    }
    if (refiGoal === 'Change ARM to Fixed') {
        theBenefit = 'change your ARM loan to a fixed loan'
    }

    //const offerText = <>{itsGood} With {translateCredit(credit).toLocaleLowerCase()} credit you may qualify to {theBenefit} with an <b>FHA {isStreamlined} Refinance</b>
    const offerText = <>{itsGood} With {credit} credit you may qualify to {theBenefit} with an <b>FHA {isStreamlined} Refinance</b>
        <p></p>
        {/*The following FHA-Approved Lender is highly recommended for next steps:
        <p></p>
        <div style={{ borderColor: 'black', border: '1px solid black', borderRadius: '10px' }}>
            <b><i>Diamond Residential Mortgage Corporation</i></b><br></br>
            <span style={{ fontSize: '12px' }}>NMLS 186805. Equal Housing Opportunity &nbsp; <img src="./imgs/h_ic_black.png" width="20px" alt="Equal Housing Opportunity Logo"></img></span><br></br>
            <a href='https://www.google.com/search?q=Diamond+Residential+Mortgage+Corporation+Oakwood+Avenue,+Lake+Forest,+Lake+County,+Illinois&ludocid=11750711965101637923&#lrd=://accounts.google.com/ServiceLogin?continue=http%3A%2F%2Fsearch.google.com%2Flocal%2Fwritereview%3Fplaceid%3DChIJP87Q2dvqD4gRI8HQOUbqEqM&rip=1&nojavascript,1'
                target='DMRC_reviews' title='DMRC Company Reviews'>
                <img src='./imgs/5-stars.png' alt='best 5 star rating' width='100px'></img>
                <br></br>
                <span style={{ fontSize: '8px' }}>5-star google reviewed</span>
            </a>
        </div>
        <p></p>*/}
        Complete the following steps to get started on your {refiGoal === 'Cash Out' ? 'cash-out' : ''} refinance rate quote.
    </>

    const offerTextAlt = <>{loNameInfo} from <br></br>
        <div style={{ borderColor: 'black', border: '1px solid black', borderRadius: '10px' }}>
            <b><i>Diamond Residential Mortgage Corporation</i></b><br></br>
            <span style={{ fontSize: '12px' }}>NMLS 186805. Equal Housing Opportunity &nbsp; <img src="./imgs/h_ic_black.png" width="20px" alt="Equal Housing Opportunity Logo"></img></span><br></br>
            <a href='https://www.google.com/search?q=Diamond+Residential+Mortgage+Corporation+Oakwood+Avenue,+Lake+Forest,+Lake+County,+Illinois&ludocid=11750711965101637923&#lrd=://accounts.google.com/ServiceLogin?continue=http%3A%2F%2Fsearch.google.com%2Flocal%2Fwritereview%3Fplaceid%3DChIJP87Q2dvqD4gRI8HQOUbqEqM&rip=1&nojavascript,1'
                target='DMRC_reviews' title='DMRC Company Reviews'>
                <img src='./imgs/5-stars.png' alt='best 5 star rating' width='100px'></img>
                <span style={{ fontSize: '8px' }}>5-star google reviewed</span>
            </a>
        </div>
         will be in contact soon to discuss your refinance options.</>

    if (!finalDisplay) {
        return offerText
    } else {
        return offerTextAlt
    }
}
import React, { Component } from 'react'
import { Modal } from 'antd'
import "antd/dist/antd.css";
import "./PrimaryOfferModal.css"
import { isDebug, campName } from '../config/configEnums'
import { logEvent, titleWord } from '../util/utilFunctions';
import ReactGA from 'react-ga'
//import Ripples from 'react-ripples'
//import CalendlyWidget from '../CalendlyWidget'
// this is drifting back towards the old ThanksThenContactInfo version
//import { SnMlistings } from '../config/OfferTargets'

type MyProps = {
    offersVisible: boolean,
    contactMethod: string,
    //creditSelection: string,
    userFirstName: string,
    userLastName: string,
    userPhone: string,
    userEmail: string,
    beEstimate: number,
}

class ThanksAndGoodbye extends Component<MyProps> {

    state = {
        modalVisible: false,
        calendlyCSS: 'calendlyInlineWidgetHidden'
    };

    setModalVisible(modalVisible: boolean) {
        this.setState({ modalVisible });
    }

    private handleClickToCalendly(): void {
        logEvent('OpenedCalendly', this.props.userFirstName + '~', this.props.userLastName, '')
        ReactGA.event({
            category: campName,
            action: 'OpenedCalendly'
        });
        alert('TODO!')
        /*        this.setState({
                    visibleGoodNewsModal: false,
                    calendlyCSS: 'calendlyInlineWidgetDisplayed'
                })*/
    }

    /*private handleClickToScheduleCall(): void {
        if (isDebug) console.log('open Calendly')
        const targetCalendar = 'drmconline/refinance'
        logEvent('toCalendly', this.props.userFirstName + ' ' +
            this.props.userLastName + '~' +
            this.props.creditSelection + '~' +
            this.props.refiGoal, 'subid', 'wiwid')
        ReactGA.event({ category: campName, action: 'Completed Step toDMCschedule' });
        window.open('https://calendly.com/' + targetCalendar +
            '?a1=1' + this.props.userPhone +
            '&email=' + this.props.userEmail +
            '&name=' + this.props.userFirstName + ' ' + this.props.userLastName)
    }


    private handleClickToDMC(): void {
        if (isDebug) console.log('open DMC App')
        logEvent('toDMCApplication', this.props.userFirstName + '~' +
            this.props.userLastName + '~' + this.props.userEmail, '', '') //this.props.subid, this.props.wiwid) //this.genS1())
        ReactGA.event({ category: campName, action: 'Completed Step toDMCapplication' });
        //document.location.href = diamondDirect
        window.open(diamondDirect)
    }*/

    private handleClickToListings(): void {
        if (isDebug) console.log('open SnM site')  // are we loosing leads here?
        logEvent('toSnMlistings', this.props.userFirstName + '~' +
            this.props.userLastName + '~' + this.props.userEmail, '', '')
        ReactGA.event({ category: campName, action: 'Completed Step toSnMlistings' });
        //document.location.href = SnMlistings
    }


    private createConditionalOfferButtonToDMC() {

        return (
            <>
                <div style={{ height: '675px', textAlign: 'center', fontSize: '22px' }}>

                    <b>Thank you, {titleWord(this.props.userFirstName)}!</b><br></br>
                    <p></p>
                    <b>Thank you for submitting your request.</b>
                    <p></p>We are working on matching you to contractors in your local area.
                    <br></br>You will receive an email from us shortly notifying you of the contractor matches we have found.
         {/* In the meantime...  We recommend the next step:
                    <br></br>

                    <br></br>
                    <b>#1 Schedule a call with one of our expert home remodelers.</b>
                    <br></br>
                    <Ripples color="#00ff00">
                        <button className={'ha-button-continue'} onClick={() => this.handleClickToCalendly()}>
                            <p className={'buttonText'}>SCHEDULE CALL</p>
                            <p style={{ position: 'absolute', top: '28%', right: '0' }}><Icon type="double-right" /> &nbsp; &nbsp; </p>
                        </button>
                    </Ripples>
                    <p>
                    </p>


                    <p></p>

                    <span style={{ fontSize: '14px', fontWeight: 'bold', lineHeight: '10px' }}>
                        this space for any extra specific disclaimer text
         </span>*/}

                </div>
{/*
                <CalendlyWidget
                    cssStyle={this.state.calendlyCSS}
                    targetParams={'a1=1' + this.props.userPhone +
                        '&email=' + this.props.userEmail +
                        '&name=' + this.props.userFirstName + ' ' + this.props.userLastName} />*/}
            </>
        )

    }


    render() {
        let contactWord = this.props.contactMethod.toLocaleLowerCase()
        if (contactWord === 'call') {
            contactWord = 'phone'
        }

        return (
            <>
                <Modal
                    key="ilThanks"
                    footer={null}
                    width={450}
                    style={{ top: '55px' }}
                    visible={this.props.offersVisible}
                    closable={false}
                >
                    {this.createConditionalOfferButtonToDMC()}
                    {isDebug ? <span style={{ color: 'red' }}>beEstimate {this.props.beEstimate}</span> : ''}
                </Modal>
            </>
        )
    }
}

export default ThanksAndGoodbye

import React, { Component } from 'react'
import { theQuestions, isDebug } from '../../config/configEnums'
import Ripples from 'react-ripples'
import { Icon, Tooltip } from 'antd'
import PlacesAutocomplete from 'react-places-autocomplete'
import { geocodeByAddress } from 'react-places-autocomplete'
import { validPostal } from '../../util/utilFunctions'
import './StreetAddressZip.css'

type MyProps = {
    handleClick(qNum: number, midVal: string, theText: string): any,
    createButton(qNum: number, theText: string, midVal: string): any,
    currentQuestion: number,
    isDisabledDisabledCSS: string,
    isDisabledCSS: string,
}

export class StreetAddressZip extends Component<MyProps> {

    state = {
        address: '', zip: '', addressSelected: '', userSelectionCSS: 'hiddenItem',
        isDisabledDisabledCSS: this.props.isDisabledDisabledCSS,
        isDisabledCSS: this.props.isDisabledCSS,
    };

    addressRef: React.RefObject<HTMLButtonElement>

    constructor(props: any) {
        super(props);
        this.addressRef = React.createRef();
    }

    handleChange = (address: any) => {
        this.setState({ address });
    };

    handleSelect = (address: any) => {
        if (isDebug) console.log('SELECTED ' + address)
        this.setState({ userSelectionCSS: '' })

        geocodeByAddress(address)
            .then(results => {
                if (isDebug) console.log(results)
                const fullAddress = results[0].formatted_address
                if (fullAddress.endsWith(', USA')) {
                    let streetAddress = fullAddress.substr(0, fullAddress.indexOf(', USA'))
                    //const thisZip = results[0].address_components[7].long_name  // not always #7, is it always last?
                    //const thisZip = results[0].address_components[results[0].address_components.length-1].long_name
                    const thisZip = streetAddress.substr(streetAddress.lastIndexOf(' ')).trim()
                    streetAddress = streetAddress.substr(0, streetAddress.lastIndexOf(' ')).trim()
                    if (isDebug) console.log('SA=' + streetAddress)
                    if (isDebug) console.log('Z=' + thisZip)
                    if (validPostal(thisZip) && streetAddress.length > 4) {  // shortest possible is ?
                        this.setState({
                            isDisabledCSS: '', isDisabledDisabledCSS: 'hiddenItem',
                            addressSelected: streetAddress, zip: thisZip
                        })
                    } else {
                        alert('Selected address must include a valid zip code.')
                        this.setState({
                            isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '',
                            addressSelected: '', zip: '', userSelectionCSS: 'hiddenItem'
                        })
                    }
                } else {
                    alert('Your address must be within the USA and include a valid zip code.')
                    this.setState({
                        isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '',
                        addressSelected: '', zip: '', userSelectionCSS: 'hiddenItem'
                    })
                }

                if (this.addressRef.current) {
                    if (isDebug) console.log('Good address selected. Address Submit Button Focused')
                    this.addressRef.current.focus()
                }

            })
            .catch(error => {
                console.error('Error', error)
                this.setState({
                    isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '',
                    addressSelected: '', zip: '', userSelectionCSS: 'hiddenItem'
                })
            });

    };


    render() {
        const qnum = theQuestions.STREETADDRESSZIP
        let gSearchControls = <div>
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <input
                            {...getInputProps({
                                placeholder: 'Enter Street Address',
                                className: 'location-search-input',
                                autoComplete: 'new-address'
                            })}
                        />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { fontSize: '22px', color: 'white', backgroundColor: '#000000', cursor: 'pointer' }
                                    : { fontSize: '18px', color: 'black', backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description} </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
        return (
            <div id="iSAZ" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>

                <span className="questionTitle"><b>What is your current street address?</b></span><br />
                {gSearchControls}
                <p></p>
                <div className={this.state.userSelectionCSS}>
                    You selected<br></br>
                    <b>{this.state.addressSelected}, {this.state.zip}</b><br></br>
                </div>
                <p></p>
                {this.state.zip === '' ? <span>This information is used for verification only.<p></p>Select your address from the list of known addresses.</span> : <span></span>}
                <p></p>
                <Tooltip title="Please enter part of and then select your full address" placement="bottom">
                    <Ripples color="#a90000">
                        <button disabled
                            className={'ha-offer-button ' + this.state.isDisabledDisabledCSS}
                        >
                            <p style={{ textAlign: 'center', lineHeight: '56px', color: '#dadada' }}>CONTINUE &nbsp; <Icon type="double-right" /> </p>
                        </button>
                    </Ripples>
                </Tooltip>
                <Ripples color="#00ff00">
                    <button
                        ref={this.addressRef}
                        className={'ha-offer-button ' + this.state.isDisabledCSS}
                        onClick={() => this.props.handleClick(qnum, this.state.addressSelected, this.state.zip)}>
                        <p style={{ textAlign: 'center', lineHeight: '56px' }}> CONTINUE &nbsp; <Icon type="double-right" /> </p>
                    </button>
                </Ripples>
                <br></br>&nbsp;
            </div >
        )
    }

}

export default StreetAddressZip

import axios from 'axios'
import { campName, campSkin, isDebugLogging } from '../config/configEnums'

export function formatDollars(dString: number): string {
    dString = Math.round(dString)
    return dString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function titleString(text: string): string {
    if (text !== undefined && text.length > 1) {
        text = text.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        return text
    } else {
        return ''
    }
}

export function titleWord(text: string): string {
    if (text !== undefined && text.length > 1) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    } else {
        return ''
    }
}


export function getLow(est: number): number {
    return Math.round(est * .8)
}
export function getHigh(est: number): number {
    return Math.round(est * 1.25)
}

export function logEvent(theStep: string, theUserInput: string, theSubid: string, theWiwidGclid: string): void {
    const reactiveContent = 'react'
    let subid = 'unk'
    if (theSubid !== '') subid = theSubid
    const targetUrl = campSkin.logURL +
        '?camp=' + campName +
        '&step=' + theStep +
        '&ui=' + theUserInput +
        '&subid=' + subid +
        '&gclid=' + theWiwidGclid +
        '&react=' + reactiveContent
    if (isDebugLogging) console.log('logging URL' + targetUrl)
    axios({
        method: 'get',
        url: targetUrl,
        responseType: 'text',
    })
        .then(res => {
            if (isDebugLogging) console.log('WIW says: ' + res.data)
        })
}


export function validPostal(s: string): boolean {
    try {
        if (s === '') return false
        if (s.length > 5) return false
        if (isNaN(+s)) return false
    } catch (e) {
        return false
    }
    return true
}


// TODO get rid of this and consolidate credit ranges to a single place
/*
export function translateCredit(s: string): string {
    let rString = 'unknown'
    if (s === '0') rString = 'Excellent (Above 720)'
    if (s === '1') rString = 'Very Good (680 to 719)'
    if (s === '2') rString = 'Good (640 to 679)'
    if (s === '3') rString = 'Fair (580 to 639)'
    if (s === '4') rString = 'Poor (Below 580)'
    return rString
}
export function translateIntent(s: string): string {
    let rString = 'unknown'
    if (s === '0') rString = 'Less than 200k'
    if (s === '1') rString = '200 to 300k'
    if (s === '2') rString = '300 to 400k'
    if (s === '3') rString = 'Over 400k'
    if (s === '4') rString = 'Dont Know'
    return rString
}
export function translateDownpayment(s: string): string {
    let rString = 'unknown'
    if (s === '0') rString = 'Less than 5%'
    if (s === '1') rString = '5 to 10%'
    if (s === '2') rString = '10 to 20%'
    if (s === '3') rString = 'Over 20%'
    return rString
}*/


/* How large of a mortgage
export function genAffordabilityEstimate(getMonthly: boolean,
    incomeSelection: string, creditSelection: string,
    currentRate: number, defaultRate: number,
    wiwJSON: any): number {
    if (isDebug) console.log('GenEst ' + incomeSelection + ' ' + creditSelection)
    const stepVal = 0.005
    const todaysRate = currentRate / 100
    const rA = [(todaysRate - (stepVal * 3)), todaysRate, (todaysRate + (stepVal * 2)), (todaysRate + (stepVal * 4))]
    if (isDebug) console.log(rA)
    const thedr = .2
    const er = +creditSelection
    let r = rA[er];
    if (isDebug) console.log('CURRENT RATE=' + currentRate)
    if ('' + currentRate === "") {
        r = defaultRate / 100
    }
    if (isDebug) console.log('Using r ' + r)
    const i = +incomeSelection
    const ampr = 0.36
    const yearTerms = 30
    const theN = yearTerms * 12
    const aap = (i * ampr) - (i * thedr)
    const amp = aap / 12
    if (getMonthly) {
        return Math.round(amp / 0.8)
    }
    let sTaxes = 0
    try {
        sTaxes = taxesDict[stateDictReversed[wiwJSON.state][0]][0] / 100;
    } catch (e) { }
    if (isDebug) console.log(sTaxes)
    const rFactor = (1 + (r / 12)) ** theN
    const ihp = (amp * (rFactor - 1)) / ((r / 12) * rFactor)
    if (isDebug) console.log('i ' + i + ', r ' + r + ', aap ' + aap + ', amp ' + amp + ', rFactor ' + rFactor + ', ihp ' + ihp + ', sTaxes ' + sTaxes)
    const ahm = Math.floor(ihp - (ihp * sTaxes))
    return Math.round(ahm / 0.8)
}

// This is effectively duplicated in goodnewsmodal...
export function genFrontEndAffordabilityEstimate(theIncome: number){
    const theRate = .04  // an ave from may 2020
    const i = theRate / 12
    const theRatio = .33 // the front end ratio
    const monthlyAvailable = (theIncome / 12) * theRatio
    const taxAndInsurance = .32 // adjust by state?
    const monthlyPI = monthlyAvailable - (taxAndInsurance * monthlyAvailable)
    const totallyAffordable = (monthlyPI / i) * (1 - Math.pow((1 + i), -360))
    if (isDebug) {
        console.log('AVE display: monthly PI ' + monthlyPI +
            ' from monthly Available of ' + monthlyAvailable +
            ' equals total affordability of $' + totallyAffordable)
    }
    const aveDP = totallyAffordable * .05
    return totallyAffordable + aveDP
}
*/
import React, { Component } from 'react'
import { theQuestions } from '../../config/configEnums'

type MyProps = {
    handleClick(qNum: number, midVal: string, theText: string): any,
    createButton(qNum: number, theText: string, midVal: string): any,
    currentQuestion: number,
}

export class BestContactTime extends Component<MyProps> {

    render() {
        const qnum = theQuestions.BESTTIME
        const iLabels = ['Morning', 'Evening', 'Anytime'] 
        const iVals = iLabels
        let indiButtons = <div></div>
        let aCtr = 0
        while (aCtr < iLabels.length) {
            indiButtons = <>{indiButtons} <div>{this.props.createButton(qnum, iLabels[aCtr], '' + iVals[aCtr])}
            </div></>
            aCtr++
        }
        return (
            <div id="iQcontactTime" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>What is the best time to contact you?</b></span><br />
                {indiButtons}
            </div >
        )
    }

}

export default BestContactTime

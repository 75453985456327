import React, { Component } from 'react'
import { campSkin } from '../config/configEnums'
import './Legal.css'
//import { Icon } from 'antd'

type MyProps = {
    theState: any
}


//function Legal() {
//const Legal = ( theState: any ) => {
export class Legal extends Component<MyProps> {
    render() {
        const currentYear = new Date().getFullYear()
        let addressInfo = <></>

        if (true) { //campSkin.siteName === 'QuantaLoan') {
            return (
                <div>
                    Copyright &copy; {currentYear} {campSkin.siteName} <img style={{ width: "20", height: "21" }} src={'./imgs/sslLogo.jpg'} alt="ssl" />
                    <br /><a style={{ color: 'blue' }} href="/legal/termsofuse.shtml" target="Terms">Terms of Use</a>
                    &nbsp; &nbsp; &nbsp; <a style={{ color: 'blue' }} href="/legal/privacypolicy.shtml" target="Privacy">Privacy Policy</a>
                    &nbsp; &nbsp; &nbsp; <a style={{ color: 'blue' }} href={'mailto:' + campSkin.siteContact}>Contact</a>
                </div>
            )
        } else {
            /*let footDivWidth = '100%' //'31%'
            if (true) { //this.props.isDiamondState) {
                footDivWidth = '48%' //'23%'
            }*/
            return (<div>
                <footer>
                    <div className="d-footer-first">

                        <div style={{ fontSize: '10px' }}>
                            {/* moved above bottom text
                        <br></br>&nbsp;
                        <br></br>
                        <img src="./imgs/diamondLogo.png" className="Footer-App-logo" alt="drmc logo" />
                        <br></br>
                        <img alt="Top 50 Customer Satisfaction" style={{ height: '50px' }} src="./imgs/endorsement_1.jpg"></img>
                        <img alt="Top 50 Best" style={{ height: '85px' }} src="./imgs/endorsement_2.jpg"></img>
                        <img alt="Top Zillow" style={{ height: '40px' }} src="./imgs/endorsement_3.jpg"></img>
                        <img alt="Top 1%" style={{ height: '50px' }} src="./imgs/endorsement_4.jpg"></img>
                        <br></br>*/}
                            <a href="https://www.diamondresidential.com/article/5" target="covid19">
                                <img src="./imgs/covid19.png" alt="covid19 alert" />
                            </a>

                            <p></p>
    NMLS #186805<br></br>
    {addressInfo}
                            <br></br>
                            <a style={{ fontSize: '10px' }} href="https://www.diamondresidential.com/licensing" target="_licensing">Licensing</a>
                            <br></br>
                            <a style={{ fontSize: '10px' }} href="mailto:applydrmc@drmconline.com">applydrmc@drmconline.com</a>
                            <br></br>
Mon/Fri 9:00AM to 5:30PM
<p></p>
                            <p style={{ fontSize: '10px' }}><span>
                                Copyright {currentYear} Diamond Residential Mortgage Corporation.
                             All Rights Reserved.<br></br>
                             Equal Housing Opportunity <br></br>
                                <img height="25px" src="./imgs/h_ic_black.png" alt="equal housing lender" />.
                                                <br></br>

                            </span></p>
                        </div>
                        {/*
                    <div className="container">
                        <div className="row">
                            <div className="d-footer-first-links">
                                <ul style={{ fontSize: '5px', lineHeight: '1px' }}>
                                    <li ><a href="https://www.diamondresidential.com/" target="_drmc" rel="noreferrer noopener"><span style={{}}>Home</span></a></li>
                                    <li ><a href="https://www.diamondresidential.com/contact" target="drmc" rel="noreferrer noopener"><span style={{}}>Contact</span></a></li>
                                    <li ><a href="https://www.diamondresidential.com/branch" target="_drmc" rel="noreferrer noopener"><span style={{}}>Find A Loan Officer</span></a></li>
                                    <li ><a href="https://www.diamondresidential.com/page/licensing" target="_drmc" rel="noreferrer noopener"><span style={{}}>Licensing</span></a></li>
                                    <li ><a href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/186805" target="_blank" rel="noreferrer noopener"><span style={{}}>NMLS Consumer Access</span></a></li>
                                    <li ><a href="http://drmc1com.sharepoint.com/" target="_self" rel="noreferrer noopener"><span style={{}}>For Employees</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="d-footer-second">
                        <div className="container">
                            <div className="row" style={{ display: 'flex' }}>
                                {true ?
                                    <div id="dmc1" style={{ width: footDivWidth }}>
                                        <p>582 Oakwood Ave
Lake Forest, IL 60045</p></div> : <></>}
                                <div id="dmc3" style={{ width: footDivWidth }}>
                                    <p style={{ fontSize: '10px' }}><a href="mailto:ApplyDRMC@drmconline.com">ApplyDRMC@drmconline.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="d-footer-third" style={{marginTop:'-10px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="span12">
                                    <div className="copyright">
                                        <p style={{ fontSize: '10px' }}><span>
                                            Equal Housing Opportunity <img height="18px" src="./imgs/h_ic_black.png" alt="equal housing lender" />.
                                                <br></br>
                                                    Copyright {currentYear} Diamond Residential Mortgage Corporation.
                                            All Rights Reserved.
                                            </span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
*/}

                    </div>

                </footer>
            </div>)
        }
        //}
    }
}

export default Legal
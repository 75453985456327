import React, { Component } from 'react'
import { theQuestions } from '../../config/configEnums'

type MyProps = {
    handleClick(qNum: number, midVal: string, theText: string): any,
    createButton(qNum: number, theText: string, midVal: string): any,
    currentQuestion: number,
}

export class BathroomShower extends Component<MyProps> {

    render() {
        const qnum = theQuestions.BATHROOMSHOWER
        const iLabels = ['Yes', 'No']
        const iVals = [1.3, .9]
        let indiButtons = <div></div>
        let aCtr = 0
        while (aCtr < iLabels.length) {
            indiButtons = <>{indiButtons} <div>{this.props.createButton(qnum, iLabels[aCtr], '' + iVals[aCtr])}
            </div></>
            aCtr++
        }
        return (
            <div id="iQwalls" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>Will your remodel involve a walk-in shower?</b></span><br />
                {indiButtons}
            </div >
        )
    }

}

export default BathroomShower

import React, { Component } from 'react'
import Legal from './Legal'
import { Spin, Icon, Modal } from 'antd'
import './MainFrame.css'
import { round50 } from '../offers/GoodNewsModal'
import axios from 'axios'
import { emailServiceURL } from '../config/OfferTargets'
import {
    campName, campSkin,
    isDebug, theQuestions, //currentMedianHouseholdIncome, iFactor,
} from '../config/configEnums'
import { logEvent, formatDollars, titleString } from '../util/utilFunctions'
import ReactGA from 'react-ga';
import Ripples from 'react-ripples'
import Name from './questions/Name'
import Email from './questions/Email'
import Phone from './questions/Phone'
//import CalendlyWidget from '../CalendlyWidget'
import ThanksAndGoodbye from '../offers/ThanksAndGoodbye'
//import TargetPrice from './questions/TargetPrice'
import BathroomType from './quinQuestions/BathroomType'
import BathroomTiles from './quinQuestions/BathroomTiles'
import BathroomQuality from './quinQuestions/BathroomQuality'
import BathroomShower from './quinQuestions/BathroomShower'
//import BathroomWalls from './quinQuestions/BathroomWalls'
import HowSoonToStart from './quinQuestions/HowSoonToStart'
import BestContactTime from './quinQuestions/BestContactTime'
import OwnHome from './quinQuestions/OwnHome'
import StreetAddressZip from './questions/StreetAddressZip'

import { Progress } from 'antd';
//import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const buttonDelay: number = 300

type MyProps = {
    qs: string,
    subid: string,
    dscs: number[],
    dscVals: any,
    wiwJSON: any | null,
    wiwid: string,
    gclid: string,
    msclkid: string,
    utmTerm: string,
    initialQuestion: any,
}

type MyState = {
    showLogo: boolean,
    percentDone: number,
    loading: boolean,
    crmId: string,
    visibleTopQuestion: boolean,
    visiblePrimaryOffer: boolean,
    visibleGoodNewsModal: boolean,
    thanksVisible: boolean,
    bathroomType: string,
    bathroomTile: string,
    bathroomQuality: string,
    bathroomTypeNum: string, //number,
    bathroomTileNum: number,
    bathroomQualityNum: number,
    theEstimate: number,
    bathroomWalls: string,
    bathroomWallsNum: number,
    bathroomShower: string,
    bathroomShowerNum: number,
    howSoon: string,
    bestTime: string,
    ownHome: string,
    propertyAddress: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    contactMethod: string,
    currentQuestion: number,
    isDisabledCSS: string,
    isDisabledDisabledCSS: string,
    isTopTextVisibleCSS: string,
    bottomTextCSS: string,
    bottomSampleQuoteText: JSX.Element,
    dynamicTitleText: string,
    topTextForEnding: JSX.Element,
    calendlyCSS: string,
    showRateQuote: boolean,
    backendEstimate: number,
    finStepsMessage: JSX.Element,
};

export const defaultRate: number = 3.81  //unused here?

class MainFrame extends Component<MyProps, MyState> {

    constructor(props: any) {
        super(props)
        this.state = {
            showLogo: true,
            percentDone: 25,
            loading: false,
            crmId: '',
            visibleTopQuestion: true,
            visiblePrimaryOffer: false,
            visibleGoodNewsModal: false,
            thanksVisible: false,
            bathroomType: '',
            bathroomTile: '',
            bathroomQuality: '',
            bathroomTypeNum: 'BATHROOM_REFACING', //10000,
            bathroomTileNum: 1,
            bathroomQualityNum: 1,
            theEstimate: 10000,
            bathroomWalls: '',
            bathroomWallsNum: 1,
            bathroomShower: '',
            bathroomShowerNum: 1,
            howSoon: '',
            bestTime: '',
            ownHome: '',
            propertyAddress: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            contactMethod: '',
            currentQuestion: this.props.initialQuestion,
            isDisabledCSS: 'hiddenItem',
            isDisabledDisabledCSS: '',
            isTopTextVisibleCSS: '',
            bottomTextCSS: '',
            bottomSampleQuoteText: <></>,
            dynamicTitleText: '',
            topTextForEnding: <></>,
            calendlyCSS: 'calendlyInlineWidgetHidden',
            showRateQuote: false,
            backendEstimate: 0,
            finStepsMessage: <span style={{ color: 'black' }}>YOUR ESTIMATE IS READY</span>
        }
    }

    componentDidMount() {
        logEvent('loaded', '', this.props.subid, this.props.wiwid + '~' + this.props.gclid)
        ReactGA.initialize('UA-67814230-1')  // this is the wiw GA code, take from HA21
        ReactGA.pageview('/' + campName);
        //console.log('WTF state Q1 = ' + this.state.currentQuestion)
        //console.log('WTF props Q1 = ' + this.props.initialQuestion)
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.initialQuestion === -1) {
            this.setState({ currentQuestion: this.props.initialQuestion })
            //console.log('WTF updating initial Q display')
        }
        // WHY doesn't isCashOut require this special treatment???  I guess because we don't initially set a local state var?
        //console.log('update WTF props ico = ' + this.props.isCashOut.toString())
        //console.log('update WTF state Q1 = ' + this.state.currentQuestion)
        //console.log('update WTF props Q1 = ' + this.props.initialQuestion)
    }



    private handleClick = (qNum: number, thisVal: string, thisLabel: string) => {
        if (isDebug) console.log(qNum + ' clicked ' + thisVal)
        logEvent(theQuestions[qNum] + '', thisVal, this.props.subid, this.props.wiwid + '~' + this.props.gclid)
        ReactGA.event({
            category: campName,
            action: 'Completed Step ' + theQuestions[+qNum]
        });

        setTimeout(() => {
            switch (+qNum) {

                case (theQuestions.BATHROOMTYPE): {
                    if (isDebug) console.log('bathroom is ' + thisVal)
                    // don't show the middle estimate if geo is not defined
                    if (this.props.wiwJSON.geo === "unk"){
                        this.setState({
                            bathroomType: thisVal,
                            bathroomTypeNum: thisVal,
                            currentQuestion: theQuestions.HOWSOON
                        })
                        break
                    }
                    this.setState({
                        bathroomType: thisVal,
                        bathroomTypeNum: thisVal,
                        currentQuestion: theQuestions.BATHROOMTILES  // to skip estimate use HOWSOON
                        //currentQuestion: theQuestions.HOWSOON //BATHROOMQUALITY //BATHROOMTILES
                    })
                    break
                }
                case (theQuestions.BATHROOMTILES): {
                    if (isDebug) console.log('bathroom tiling is ' + thisVal)
                    this.setState({
                        bathroomTile: thisVal,
                        bathroomTileNum: +thisVal,
                        currentQuestion: theQuestions.BATHROOMSHOWER
                    })
                    if (isDebug) console.log(document.getElementById('trustThis')?.innerHTML);
                    break
                }
                case (theQuestions.BATHROOMSHOWER): {
                    if (isDebug) console.log('bathroom shower is ' + thisVal)
                    this.setState({
                        bathroomShower: thisVal,
                        bathroomShowerNum: +thisVal,
                        currentQuestion: theQuestions.BATHROOMQUALITY //WALLS
                    })
                    break
                }
                /*case (theQuestions.BATHROOMWALLS): {
                    if (isDebug) console.log('bathroom walls is ' + thisVal)
                    this.setState({
                        bathroomWalls: thisVal,
                        bathroomWallsNum: +thisVal,
                        currentQuestion: theQuestions.BATHROOMQUALITY
                    })
                    break
                }*/
                case (theQuestions.BATHROOMQUALITY): {
                    if (isDebug) console.log('bathroom finish is ' + thisVal)
                    const aveIncome: number = 60000
                    const regionalFactor: number = this.props.wiwJSON.income / aveIncome
                    if (isDebug) console.log('regionalFactor is ' + regionalFactor)
                    const iVals = [2500, 2000, 2200, 3500, 5500]
                    const aVals = [
                        'BATHROOM_REFACING',
                        'BATHROOM_REFACING',
                        'BATHROOM_REFACING',
                        'WALK_IN_SHOWERS',
                        'BATH_REMODEL'
                    ]
                    let bTypeNum = iVals[0]
                    if (this.state.bathroomTypeNum === aVals[1]) bTypeNum = iVals[1]
                    if (this.state.bathroomTypeNum === aVals[2]) bTypeNum = iVals[2]
                    if (this.state.bathroomTypeNum === aVals[3]) bTypeNum = iVals[3]
                    if (this.state.bathroomTypeNum === aVals[4]) bTypeNum = iVals[4]

                    this.setState({
                        theEstimate: (regionalFactor * bTypeNum * //this.state.bathroomTypeNum *
                            this.state.bathroomWallsNum * this.state.bathroomShowerNum *
                            this.state.bathroomTileNum * +thisVal),
                        bathroomQuality: thisVal,
                        bathroomQualityNum: +thisVal,
                        //currentQuestion: theQuestions.NAME,  // handled by Continue button
                        visibleGoodNewsModal: true
                    })
                    break
                }


                case (theQuestions.HOWSOON): {
                    if (isDebug) console.log('starting project: ' + thisVal)
                    this.setState({
                        percentDone: 40,
                        howSoon: thisVal,
                        currentQuestion: theQuestions.OWNHOME
                    })
                    break
                }
                case (theQuestions.OWNHOME): {
                    if (isDebug) console.log('own home? ' + thisVal)
                    this.setState({
                        percentDone: 55,
                        ownHome: thisVal,
                        currentQuestion: theQuestions.BESTTIME
                    })
                    break
                }
                case (theQuestions.BESTTIME): {
                    if (isDebug) console.log('best time to contact ' + thisVal)
                    this.setState({
                        percentDone: 70,
                        bestTime: thisVal,
                        currentQuestion: theQuestions.STREETADDRESSZIP
                    })
                    break
                }


                case (theQuestions.STREETADDRESSZIP): {
                    if (isDebug) console.log('Process Address: ' + thisVal)
                    this.setState({
                        percentDone: 80,
                        currentQuestion: theQuestions.NAME
                    })
                    if (thisVal !== '') {
                        this.setState({
                            propertyAddress: thisVal + ', ' + thisLabel,
                        })
                    }
                    break
                }


            }
        }, buttonDelay);
    }


    private createButton(qNum: number, theText: string, midVal: string) {
        let buttonStyle = 'ha-button-continue'
        if (qNum === theQuestions.BATHROOMTYPE) {
            buttonStyle = 'ha-button-continue2'
        }
        return (
            <>
                <Ripples color={campSkin.buttonClickColor} during={buttonDelay}>
                    <button className={buttonStyle}
                        onClick={() => this.handleClick(qNum, midVal, theText)}>
                        <p className={'buttonText'}>{theText.toUpperCase()}</p>
                        <p style={{ position: 'absolute', top: '28%', right: '0' }}><Icon type="right" /> &nbsp; </p>
                    </button>
                </Ripples>
                <div style={{ height: '7px' }}></div>
            </>
        )
    }


    private handleNameClick = (fName: string, lName: string) => {
        if (isDebug) console.log('Name Set to: ' + fName + '~' + lName)
        this.setState({
            percentDone: 90,
            //thanksVisible: true,
            //visibleGoodNewsModal: false,
            firstName: fName,
            lastName: lName,
            currentQuestion: theQuestions.EMAIL,
            isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '',
        })
        if (isDebug) console.log('Name Set: ' + this.state.firstName + '~' + this.state.lastName)
        logEvent('NAME', fName + '~' + lName, this.props.subid, this.props.wiwid + '~' + this.props.gclid)
        ReactGA.event({ category: campName, action: 'Completed Step NAME' })
    }


    private createUrl(theEmail: string, thePhone: string, theContactMethod: string): string {
        let emailServiceBase = emailServiceURL
        //if (isDebug) emailServiceBase = emailTestServiceURL
        let cityState = 'unk'
        if (this.props.wiwJSON) {
            cityState = this.props.wiwJSON.name + ',' + this.props.wiwJSON.state + ',' + this.props.wiwJSON.zip
        }
        let theSource = ''  // TODO move this logic into service?
        if (this.props.gclid !== '' && this.props.gclid !== 'gclid') {
            theSource = 'Google'
        }
        if (this.props.msclkid !== '' && this.props.msclkid !== 'msclkid') {
            theSource = 'Bing'
        }


        /*
        const haMonthlyEstimate = generateHAMonthlyEstimate(0, +this.state.incomeSelection)
        const haTotalEstimate = round50(generateHAEstimate(haMonthlyEstimate, 5, +this.state.creditSelection))
        if (isDebug) {
            console.log('ESTIMATE SENT TO MIDDLE ' + haTotalEstimate + ' and ' + haMonthlyEstimate)
        }

        const monthlyDebt = +this.state.monthlyAutoDebt +
            +this.state.monthlyChildDebt +
            +this.state.monthlyCreditCardDebt +
            (+this.state.totalStudentLoans * .01)
        const BEhaMonthlyEstimate = generateBEHAMonthlyEstimate(monthlyDebt, +this.state.incomeSelection)
        const BEhaTotalEstimate = round50(generateHAEstimate(BEhaMonthlyEstimate, 5, +this.state.creditSelection))
        this.setState({ backendEstimate: BEhaTotalEstimate })
        if (isDebug) {
            console.log('Back End ESTIMATE SENT TO MIDDLE ' + BEhaTotalEstimate + ' and ' + BEhaMonthlyEstimate)
        }*/
        return emailServiceBase + '?s1=' + this.genS1() +
            '&wiwid=' + this.props.wiwid +
            '&fName=' + encodeURI(this.state.firstName) +
            '&lName=' + encodeURI(this.state.lastName) +
            '&email=' + theEmail +
            '&phone=' + thePhone +
            '&contactMethod=' + theContactMethod +
            '&cityState=' + cityState +
            '&utmTerm=' + this.props.utmTerm +
            '&camp=' + campName +
            '&source=' + theSource +
            '&bathroomType=' + this.state.bathroomType +
            '&bathroomTile=' + this.state.bathroomTile +
            '&bathroomQuality=' + this.state.bathroomQuality +
            '&walls=' + this.state.bathroomWallsNum +
            '&shower=' + this.state.bathroomShowerNum +
            '&bathroomEstimate=' + round50(this.state.theEstimate) +
            '&bestTime=' + this.state.bestTime +
            '&howSoon=' + this.state.howSoon +
            '&ownHome=' + this.state.ownHome +
            '&address=' + this.state.propertyAddress +
            '&crmId=' + this.state.crmId +
            '&tf=' + document.getElementById('trustThis')?.innerHTML +
            '&qs=' + encodeURI(this.props.qs.split('&').join('~'))
    }

    private handleEmailClick = (theEmail: string) => {
        this.setState({ email: theEmail })
        if (isDebug) console.log('Email Set: ' + theEmail)
        /*const targetUrl = this.createUrl(theEmail, '', 'Email')
        if (isDebug) console.log(targetUrl)
        axios({
            method: 'get',
            url: targetUrl,
            responseType: 'text',
        })
            .then(res => {
                if (isDebug) console.log('WIW says: ' + res.data)
                try {
                    let findId: string = res.data
                    findId = findId.substring(findId.indexOf('CRMIDSTART') + 10, findId.indexOf('CRMIDEND'))
                    if (findId.length > 10 && findId.length < 24) {
                        this.setState({ crmId: findId })
                    } else {
                        if (isDebug) console.log('Bad ID')
                    }
                    if (isDebug) console.log('crmId=' + findId)
                } catch (e) {
                    if (isDebug) console.log('crmId Error: ' + e)
                }
                logEvent('EMAIL', theEmail, this.props.subid, this.props.wiwid + '~' + this.props.gclid)
                ReactGA.event({ category: campName, action: 'Completed Step EMAIL' });
            })*/
        this.setState({
            visibleTopQuestion: false,
            percentDone: 100,
            currentQuestion: theQuestions.PHONE,
            isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '',
            bottomTextCSS: 'hiddenItem',
            topTextForEnding: <>You are one step away from an exclusive bathroom remodel estimate.</>
        })
        logEvent('EMAIL', theEmail, this.props.subid, this.props.wiwid + '~' + this.props.gclid)
        ReactGA.event({ category: campName, action: 'Completed Step EMAIL' });
    }

    private handlePhoneClick = (phoneNumber: string, contactMethod: string) => {
        const phoneButtonDelay = 500
        setTimeout(() => {
            this.setState({ phone: phoneNumber, contactMethod: contactMethod, loading: true })
            if (isDebug) console.log('Phone Set: ' + phoneNumber)
            const targetUrl = this.createUrl(this.state.email, phoneNumber, contactMethod)
            if (isDebug) console.log(targetUrl)
            axios({
                method: 'get',
                url: targetUrl,
                responseType: 'text',
            })
                .then(res => {
                    this.setState({ thanksVisible: true, loading: false })
                    if (isDebug) console.log('WIW says: ' + res.data)
                    try {
                        document.title = 'Thanks!'
                        logEvent('PHONE', phoneNumber, this.props.subid, this.props.wiwid + '~' + this.props.gclid)
                        ReactGA.event({ category: campName, action: 'Completed Step PHONE' });
                    } catch (e) {
                        if (isDebug) console.log('MSG email or unpacking error: ' + e)
                    }
                })
        }, phoneButtonDelay)
    }


    private genS1(): string {
        const subArray = this.props.subid.split('~')
        let eID = '.'
        let dID = '.'
        try {
            eID = subArray[1]
            if (eID.length > 1) {
                eID = '0'
            }
        } catch (e) {
            eID = '-'
        }
        try {
            dID = subArray[2]
            if (dID.length > 1) {
                dID = '0'
            }
        } catch (e) {
            dID = '-'
        }

        return campName + "~" +
            this.props.wiwid + "~" +
            eID + "~" + dID + '~000~' +
            this.props.gclid +
            '&s2=' + this.props.subid + '&s3=' +
            this.props.gclid + '&s4=' +
            this.props.wiwid + '~' + (this.props.wiwJSON ? this.props.wiwJSON.zip : '')
    }


    private genTopText() {
        /*let geoState = 'your state'
        try {
            if (this.props.wiwJSON) {
                geoState = this.props.wiwJSON.state
            }
        } catch (e) {
            if (isDebug) console.log(e)
        }*/
        return (
            <>
                <div className={this.state.isTopTextVisibleCSS} style={{ fontSize: '14px' }}></div>
                {this.state.topTextForEnding}
            </>
        )
    }


    // This is really part of genBottomText now...
    private createOfferButtons(showCalendlyButton: boolean) {
        //let geoName = 'your area'
        //let dropStateName = ''
        /*let pTaxText = ' '
        //let stateNickname = 'resident'*/
        try {
            if (this.props.wiwJSON) {
                //geoName = this.props.wiwJSON.name
                /*
                if (this.props.wiwJSON.state !== '') {
                    dropStateName = ', ' + this.props.wiwJSON.state
                    //const stateCode = stateDictReversed[this.props.wiwJSON.state][0]
                    //const stateNicknameArray = stateDict[stateCode][2].split(',')
                    //const randNicknameChoice = stateNicknameArray[Math.floor(Math.random() * stateNicknameArray.length)]
                    //if (isDebug) console.log(stateNicknameArray + '~' + randNicknameChoice + '~')
                    //stateNickname = randNicknameChoice
                }*/
                //pTaxText = dropStateName + ' property taxes of ' + taxesDict[stateDictReversed[this.props.wiwJSON.state][0]][0] + '% '
            }
        } catch (e) {
            if (isDebug) console.log(e)
        }

        /*let medianIncome = currentMedianHouseholdIncome
        try {
            if (this.props.wiwJSON) {
                medianIncome = +this.props.wiwJSON.income
            }
        } catch (e) {
            if (isDebug) console.log(e)
        }

        let i = medianIncome
        try {
            i = i * iFactor
        } catch (e) {
            i = currentMedianHouseholdIncome
        }*/

        //const aveMonthlyEstimate = generateHAMonthlyEstimate(0, i)
        //const aveHAestimate = generateHAEstimate(aveMonthlyEstimate, 5, 680)
        return (
            <>
                {this.state.bottomSampleQuoteText}
                {/* <div className={this.state.bottomTextCSS}>
                    <br /><p style={{ color: 'black', textAlign: 'center', fontSize: '100%' }}>
                        <b className="bTitle">
                            Bathroom Remodel Calculator for {geoName}</b></p>

                    The <i> {campSkin.siteName} </i> Bathroom Project
helps you {this.props.dscVals.dsc3} what you can expect based on your personal circumstances.
                </div> */}
                {/*this.state.bottomTextCSS === 'hiddenItem' ? <><p></p>{updatedBottomText}<p></p></> : ''*/}
            </>
        )
    }


    private showQuote = () => {
        ReactGA.event({ category: campName, action: 'Viewed Example Quote' })
        this.setState({ showRateQuote: true })
    }
    private closeExampleQuote = () => {
        this.setState({ showRateQuote: false })
    }

    private genBottomText() {
        return (
            <>
                <br></br>
                {this.createOfferButtons(true)}
                <div className={this.state.bottomTextCSS}>

                    <b>
                        {this.props.dscVals.dsc4 ? titleString(this.props.dscVals.dsc4) : ''}  Average Bathroom {this.props.dscVals.dsc2 ? titleString(this.props.dscVals.dsc2) : ''}
                    </b>
                    <div style={{ textAlign: 'justify' }}>
                        <p></p>
The average cost of a bathroom remodeling project is approximately $10,000.  This includes minor,
 partial, and small bathroom remodeling costs. A comprehensive bathroom remodel is likely to cost $15,000
  or more; a large master bath remodel can easily go over $50,000.  It’s important for you to know not just
   the total cost of the estimates you receive from bathroom contractors but also where that money is going.
    Understanding this cost breakdown will help you choose the bid with the best overall value and, hopefully,
     identify contractors who may be giving you unrealistically low, misleading bids.
<p></p>
Our Bathroom Remodel estimate tool will help you estimate the cost of your project.  Then you can take
 the next step by requesting a quote from a local contractor.
 </div>

                    <p></p>
                </div>
            </>
        )
    }


    private handleEstimateContinueClick(): void {
        //const theHAestimate = generateHAEstimate(haMonthlyEstimate, 5, +this.state.creditSelection)
        logEvent('ContinuedLF', '~~', this.props.subid, this.genS1())
        ReactGA.event({
            category: campName,
            action: 'Continued' //F with ' + this.state.refiGoal
        });
        //let topTextCO: string = 'Continue for a personalized rate and mortgage savings quote.'

        this.setState({
            showLogo: false,
            percentDone: 25,
            currentQuestion: theQuestions.HOWSOON, //NAME,
            bottomTextCSS: 'hiddenItem',
            visibleGoodNewsModal: false,
            isTopTextVisibleCSS: 'hiddenItem',
        })
        //document.location.href = ltDirectBase + this.genS1()
    }
    private createEstimateContinueButton() {
        return (
            <Ripples color="#00ff00">
                <button className='ha-button-continue' onClick={() => this.handleEstimateContinueClick()}>
                    <p className={'buttonText'}>CONTINUE</p>
                    <p style={{ position: 'absolute', top: '28%', right: '0' }}><Icon type="double-right" /> &nbsp; &nbsp;</p>
                </button>
            </Ripples>
        )
    }



    render() {
        const spinIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

        //const haMonthlyEstimate: number = generateHAMonthlyEstimate(0, +this.state.incomeSelection)
        //const haTotalEstimate: number = round50(generateHAEstimate(haMonthlyEstimate, 5, +this.state.creditSelection))

        return (
            <div className="contentWrapper">
                <div>
                    <header>
                        &nbsp;
                        <br></br>
                        {this.state.showLogo ?
                            <img src={'./imgs/' + campSkin.logoImage}
                                className={"App-logo"}
                                alt="site logo" />
                            :
                            <div><b>
                                {this.state.percentDone === 100 ?
                                    <span style={{ color: 'green', fontSize: '18px' }}>Final Step!</span> :
                                    <span style={{ color: 'black', fontSize: '18px' }}>Progress Towards a Precise Estimate</span>}
                            </b><br></br>
                                <Progress type="circle" percent={this.state.percentDone} />
                            </div>
                        }
                        <p></p>

                        <div className={this.state.visibleTopQuestion ? 'tTitle' : 'hiddenText'}>
                            <span style={{ fontSize: "22px" }}>
                                {/* this.state.dynamicTitleText === '' ? 'Can ' + this.props.dscVals.dsc1Alt1 + ' Get a ' + this.props.dscVals.dsc2 + '?' : this.state.dynamicTitleText */}
                                {/* GET YOUR {this.props.dscVals.dsc1 ? this.props.dscVals.dsc1.toUpperCase() : ''} {this.props.dscVals.dsc2Alt1 ? this.props.dscVals.dsc2Alt1.toUpperCase() : ''} AND CLOSING COSTS ESTIMATE IN 2 SIMPLE STEPS */}
                                {/* &#x1f6bb; GET YOUR BATHROOM ESTIMATE IN A FEW SIMPLE STEPS */}
                                &#x1f6bb; {this.props.dscVals.dsc4 ? this.props.dscVals.dsc4.toUpperCase() : 'ESTIMATE'}&nbsp;
                                YOUR {this.props.dscVals.dsc1 ? this.props.dscVals.dsc1.toUpperCase() : 'BATHROOM'}&nbsp;
                                {this.props.dscVals.dsc2 ? this.props.dscVals.dsc2.toUpperCase() : 'REMODEL'}&nbsp;
                                {this.props.dscVals.dsc3 ? this.props.dscVals.dsc3.toUpperCase() : 'COSTS'} IN A FEW SIMPLE STEPS
                                {/* &#x1f6bd; */}
                            </span>
                        </div>
                        <div className={this.state.visibleTopQuestion ? 'hiddenText' : 'tTitle'}>
                            <span style={{ fontSize: "22px" }}>
                                {this.state.finStepsMessage}
                            </span>
                        </div>
                        <p style={{ lineHeight: '10%' }} />
                    </header>
                    <div style={{ backgroundColor: "#ebebeb", paddingLeft: '8px', paddingRight: '10px' }}>
                        <div>{this.genTopText()}</div>
                        <Spin indicator={spinIcon} spinning={this.state.loading} />
                        <BathroomType
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />
                        <BathroomTiles
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />
                        <BathroomShower
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />
                        {/*
                        <BathroomWalls
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />*/}
                        <BathroomQuality
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />

                        <HowSoonToStart
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />
                        <OwnHome
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />
                        <BestContactTime
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                        />


                        <StreetAddressZip
                            handleClick={this.handleClick}
                            createButton={this.createButton}
                            currentQuestion={this.state.currentQuestion}
                            isDisabledDisabledCSS={this.state.isDisabledDisabledCSS}
                            isDisabledCSS={this.state.isDisabledCSS}
                        />

                        <Name
                            handleNameClick={this.handleNameClick}
                            currentQuestion={this.state.currentQuestion}
                            isDisabledDisabledCSS={this.state.isDisabledDisabledCSS}
                            isDisabledCSS={this.state.isDisabledCSS}
                        />
                        <Email
                            handleEmailClick={this.handleEmailClick}
                            currentQuestion={this.state.currentQuestion}
                            isDisabledDisabledCSS={this.state.isDisabledDisabledCSS}
                            isDisabledCSS={this.state.isDisabledCSS}
                        />
                        <Phone
                            handlePhoneClick={this.handlePhoneClick}
                            currentQuestion={this.state.currentQuestion}
                            isDisabledDisabledCSS={this.state.isDisabledDisabledCSS}
                            isDisabledCSS={this.state.isDisabledCSS}
                        />
                    </div>
                    <div>{this.genBottomText()}</div>
                    <div><Legal theState={this.props.wiwJSON ? this.props.wiwJSON.state : ''} /></div>
                    <ThanksAndGoodbye
                        offersVisible={this.state.thanksVisible}
                        contactMethod={this.state.contactMethod}
                        userFirstName={this.state.firstName}
                        userLastName={this.state.lastName}
                        userPhone={this.state.phone}
                        userEmail={this.state.email}
                        beEstimate={this.state.backendEstimate}
                    />
                    <Modal
                        zIndex={0}
                        key="intermediateEstimate"
                        footer={null}
                        width={'85%'}
                        style={{ top: '10px', maxWidth: '500px' }}
                        visible={this.state.visibleGoodNewsModal}
                        closable={false}
                        maskClosable={false}
                        onCancel={() => this.handleEstimateContinueClick()}
                    >
                        <div style={{ height: '500px', textAlign: 'center' }}>
                            <b>
                                The exact cost of a bathroom remodal depends on many factors.
                                These factors include but are not limited to the location of your home,
                                the quality of materials and availability of qualified contractors.
                                <p></p>
                                Based on your answers so far this bathroom remodel could cost between <br></br>
                                <b style={{ color: 'green' }}>${formatDollars(round50(.70 * +this.state.theEstimate))}</b> to&nbsp;
                                <b style={{ color: 'green' }}>${formatDollars(round50(1.40 * +this.state.theEstimate))}</b>.
                                <p></p>
                                Answer a few more questions to receive a more precise estimate
                                 and exclusive offers.
                            </b>
                            <p></p>
                            {this.createEstimateContinueButton()}
                            <p />
                            <p style={{ lineHeight: '10px', height: '8px', fontSize: '9px' }}>
                                <br></br>
                            </p>
                            <div onClick={() => this.handleEstimateContinueClick()}>
                                <img src={'./imgs/' + campSkin.logoImage}
                                    className={"App-logo"}
                                    alt="site logo"
                                />
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        zIndex={1}
                        key="exampleRateQuote"
                        visible={this.state.showRateQuote}
                        closable={true}
                        footer={null}
                        maskClosable={true}
                        onCancel={() => this.closeExampleQuote()}
                    >
                        <img src="./imgs/Pre-Qual.JPG"
                            style={{ width: '100%' }}
                            alt="example rate quote" />
                    </Modal>
                    {isDebug ? <div style={{ color: 'red', textAlign: 'left' }}>
                        theEstimate = {this.state.theEstimate}
                        qs={this.props.qs}<br />
                        crmId={this.state.crmId}<br></br>
                        state={JSON.stringify(this.state)}<br />
                        dscs={this.props.dscs.toString()}<br />
                        DSCS={JSON.stringify(this.props.dscVals)}<br />
                        wiwJSON={JSON.stringify(this.props.wiwJSON)}</div> : ''}
                </div>
                {/*
                <CalendlyWidget
                    cssStyle={this.state.calendlyCSS}
                    targetParams={'a1=' + this.state.phone +
                        '&a2=999' +
                        '&name=' + this.state.firstName + ' ' + this.state.lastName +
                    '&email=' + this.state.email} />*/}
            </div>
        )
    }
}

export default MainFrame

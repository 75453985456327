import React, { Component } from 'react'
import { theQuestions, isDebug, campName } from '../../config/configEnums'
import { Modal, Input, Tooltip, Icon } from 'antd'
import Ripples from 'react-ripples'

type MyProps = {
    handleNameClick(fName: string, lName: string): any,
    currentQuestion: number,
    isDisabledDisabledCSS: string,
    isDisabledCSS: string,
}

export class Name extends Component<MyProps> {

    state = {
        firstName: '',
        lastName: '',
        isDisabledDisabledCSS: this.props.isDisabledDisabledCSS,
        isDisabledCSS: this.props.isDisabledCSS,
        showEgg1: false,
        showEgg2: false,
    }

    private onFirstNameChange = (e: any) => {
        this.setState({ firstName: e.target.value })
        this.checkToEnableNameButton(e.target.value, this.state.lastName)
    }
    private onLastNameChange = (e: any) => {
        this.setState({ lastName: e.target.value })
        this.checkToEnableNameButton(this.state.firstName, e.target.value)
    }
    private checkToEnableNameButton(thisFirstName: string, thisLastName: string) {
        if (isDebug) { console.log('checking ' + thisFirstName + ' ' + thisLastName) }
        if (thisFirstName === "Dr" && thisLastName === "Bugz") {
            this.setState({ showEgg1: true })
        }
        if (thisFirstName === "Mr" && thisLastName === "Money") {
            this.setState({ showEgg2: true })
        }
        if (thisFirstName.length > 0 && thisLastName.length > 1) {
            this.setState({ isDisabledCSS: '', isDisabledDisabledCSS: 'hiddenItem' })
        } else {
            this.setState({ isDisabledCSS: 'hiddenItem', isDisabledDisabledCSS: '' })
        }
    }

    handleCancel1 = (e: any) => {
        console.log(e);
        this.setState({
            showEgg1: false,
        });
    };
    handleCancel2 = (e: any) => {
        console.log(e);
        this.setState({
            showEgg2: false,
        });
    };


    render() {
        const qnum = theQuestions.NAME

        return (
            <div id="iNAME" className={this.props.currentQuestion === qnum ? '' : 'hiddenItem'}>
                <span className="questionTitle"><b>Name For Quote</b></span><br />
                <Input className="textInputStyle"
                    maxLength={25}
                    defaultValue={isDebug ? 'TEST' : ''}
                    placeholder="First Name"
                    key="firstName"
                    onChange={this.onFirstNameChange} />
                <p></p>
                <Input className="textInputStyle"
                    maxLength={25}
                    placeholder="Last Name"
                    key="lastName"
                    onChange={this.onLastNameChange} />
                <p></p>
                <Tooltip title="Please Enter Your First and Last Name" placement="bottom">
                    <Ripples color="#a90000">
                        <button disabled
                            className={'ha-button-continue ' + this.state.isDisabledDisabledCSS}
                        >
                            <p style={{ textAlign: 'center', lineHeight: '56px', color: '#dadada' }}>CONTINUE &nbsp; <Icon type="double-right" /> </p>
                        </button>
                    </Ripples>
                </Tooltip>
                <Ripples color="#00ff00">
                    <button
                        className={'ha-button-continue ' + this.state.isDisabledCSS}
                        onClick={() => this.props.handleNameClick(this.state.firstName, this.state.lastName)}>
                        <p style={{ textAlign: 'center', lineHeight: '56px' }}> CONTINUE &nbsp; <Icon type="double-right" /> </p>
                    </button>
                </Ripples>
                <br></br>
                &nbsp;
                <Modal
                    key="showEgg1"
                    footer={null}
                    width={350}
                    style={{ top: '125px' }}
                    visible={this.state.showEgg1}
                    closable={true}
                    maskClosable={true}
                    onCancel={this.handleCancel1}
                >
                    <div style={{ textAlign: "center" }}>
                        <h1>Good job, Dr. Bugz!</h1>
                        <br></br>
                        <img src={"/" + campName + "/imgs/John-Klopp-Paid-Search-Marketing-Technologist.png"} alt="John Emoji"></img>
                    </div>
                </Modal>
                <Modal
                    key="showEgg2"
                    footer={null}
                    width={350}
                    style={{ top: '125px' }}
                    visible={this.state.showEgg2}
                    closable={true}
                    maskClosable={true}
                    onCancel={this.handleCancel2}
                >
                    <div style={{ textAlign: "center" }}>
                        <h1>Good job, Mr. M!</h1>
                        <br></br>
                        <img src={"/" + campName + "/imgs/Matt-Eskew-Paid-Search-Marketing-Expert.png"} alt="Matt Emoji"></img>
                    </div>
                </Modal>
                {/*
                <p></p>
                <p></p>
                <div style={{ height: '70px' }}>
                    <p style={{ lineHeight: '10px', height: '8px', fontSize: '9px' }}>
                        {this.props.isLocalCashOut ? '' : 'Savings estimate based on a 30 year fixed conventional rate of ' + this.props.theRate + '% with an APR of ' + this.props.theAPR + '%.'} This is not an actual mortgage interest rate quote and should not be a conceived as actual mortgage quote or requirement to do a loan. This is for informational purposes only, for a mortgage quote please continue and contact a mortgage loan originator to obtain an actual quote and to review numbers. Terms and qualification may vary.
                </p>
                </div>
        */}
            </div>
        )


    }

}

export default Name
